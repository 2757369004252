import React, {useEffect, useState} from "react";
import {message, Tag} from "antd";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {useRole, useTitle} from "../../../components/hook";
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";
import TableTemplate from "../../../components/table";

export default function ListGroupUser(){
    const {accessToken} = useSelector(state => state.authUser);
    const param = useParams();
    const [loadingGU, setLoadingGU] = useState(true);
    const [user, setUser] = useState([]);
    useRole(['ROOT','SUPERADMIN']);
    useTitle('Liste des Utilisateurs');
    const fetchUsers = () => {
        try {
            setLoadingGU(true);
            axiosInstance(accessToken).get('user/list/'+parseInt(param.id)).then(
                (responses)=>{
                    setUser(responses.data);
                    setLoadingGU(false);
                }).catch(
                error=>{
                    message.error(error.response.data.message)
                    setLoadingGU(false);
                }
            );
        } catch (error) {
            setLoadingGU(false);
        }
    };
    useEffect(() => {
        fetchUsers();
    }, []);
    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Nom',
            key: 'name',
            render:  (_, record) => (
                <>
          <span>
            {record.nom} {record.prenom}
          </span>
                </>
            ),
        },
        {
            title: 'Status',
            key: 'status',
            render:  (_, record) => {
                let result = null;
                if(record.status === 'ACTIVE'){
                    result = (
                        <Tag color="green" key={record.id} >
                            Activer
                        </Tag>
                    )
                }else if(record.status === 'INACTIVE'){
                    result = (
                        <Tag color="volcano"  key={record.id}>
                            Desactiver
                        </Tag>
                    )
                }else {
                    result = (
                        <Tag color="volcano"  key={record.id}>
                            Unknow
                        </Tag>
                    )
                }
                return (
                    <>
                        {result}
                    </>
                )
            } ,
        },
    ];

    return (
        <>
            <h1 style={{marginTop: '15px'}}>Liste des utilisateurs</h1>
            <TableTemplate columns={columns} loading={loadingGU} datas={user} />
        </>
    );
}