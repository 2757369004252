import React, { useState, useEffect } from 'react';
import {Button, Form, Input, Modal, Space, message, Upload, Select} from 'antd';
import {PlusCircleFilled, UploadOutlined} from "@ant-design/icons";
import { EditorState, convertToRaw, RichUtils } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './Add.css';
import { useSelector } from "react-redux";
import { convertToHTML } from 'draft-convert';
import FontSizePicker from '../toolbar/FontSize';
import FontFamilyPicker from '../toolbar/FontFamilyPicker';
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";
import {useRole} from "../../../components/hook";


export default function AddArticle({action, style}) {
  const {accessToken} = useSelector(state => state.authUser);
  const [form] = Form.useForm();
  const [editorState, setEditorState] = useState(
    () => EditorState.createEmpty()
  );
  useRole(['ROOT','ADMIN','SUPERADMIN']);

  const [file, setFile] = useState(null);
  const getFiles = (event)=>{
    if(event.target.files.length>0) {
      setFile(event.target.files[0]);
    }
  }
  const handleFontSizeChange = (fontSize) => {
    const newEditorState = RichUtils.toggleInlineStyle(editorState, `FONT_SIZE_${fontSize}`);
    setEditorState(newEditorState);
  };  
  const handleFontChange = (selectedFont) => {
    const newEditorState = RichUtils.toggleInlineStyle(editorState, `FONT_${selectedFont}`);
    setEditorState(newEditorState);
  };
  const [convertedContent, setConvertedContent] = useState(null);

  useEffect(() => {
    let html = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(html);
    setFile(null);
    form.resetFields();
  }, []);


  const handleEditorChange = newEditorState => {
    setEditorState(newEditorState);
  };
  const [modalAddOpen, setModalAddOpen] = useState(false);
  const [loading, setLoading] = useState(false);
const openModal =()=>{
  setEditorState(() => EditorState.createEmpty());
  setLoading(false);
  setModalAddOpen(true)
}
  const onClose = () => {
    setLoading(true)
    setModalAddOpen(false);
    form.resetFields();

  }
  const onSubmit = async (values) => {
    if(editorState.getCurrentContent().getPlainText().trim().length!=0){
      if(file === null){
        message.error('Choisir le logo du centre partenaire');
      }else {
        setLoading(true);
        const dat ={
          "id":null,
          "title":values.title,
          "author":values.author,
          "contenu":JSON.stringify(convertToRaw(editorState.getCurrentContent())),
          "categorie":values.categorie,
          "typeDonne":"ARTICLE",
          "status":false,
          "phote":null,
          "lue":0,
        }
        const formData = new FormData();
        formData.append('file', file);
        formData.append('jsonData', JSON.stringify(dat));
        let config = {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
        axiosInstance(accessToken).post(
            "api/article",
            formData, config
        ).then((result)=> {
          message.info("Done ...");
          setModalAddOpen(false);
          window.location.reload();
          setLoading(false);
        }).catch((result)=>{
          setLoading(false);
          message.error(result.data.message)
        });
      }

    }else{
      message.error("Le contenu de l'article est vide")
    }
  }

  return(
    <>
      <Button style={{float: "right"}}  onClick={openModal} >
        <i aria-hidden="true"><PlusCircleFilled /></i> {action}
          </Button>
          <Modal
          title="Creation d'un article"
          style={{
            top: 20,
          }}
          width={1000}
          open={modalAddOpen}
          maskClosable={false}
          closable={false}
          footer={[]}
        >
          <Form onFinish={onSubmit} form={form}>
            <Form.Item
              label="Titre"
              name="title"
              rules={
                [
                  { required: true, message: 'Veuillez entrer le titre' },
                  {type:"string", message:""}
                ]}
              hasFeedback>
              <Input />
            </Form.Item>
            <Form.Item
              label="CATEGORIE"
              name="categorie"
              rules={
                [
                  { required: true, message: 'Selectionner la Categorie' }
                ]}
              hasFeedback>
              <Select placeholder="Choisir la Categorie">
                <Select.Option value="SANTE_SEXUELLE">SANTE SEXUELLE</Select.Option>
                <Select.Option value="IVG">INTERRUPTION VOLONTAIRE GROSSESE</Select.Option>
                <Select.Option value="PLANNING_FAMILLIAL">PLANNING FAMILLIAL</Select.Option>
                <Select.Option value="VIH_IST">VIH/IST</Select.Option>
                <Select.Option value="HYGENE_MENSTRUELLE">HYGENE MENSTRUELLE</Select.Option>
                <Select.Option value="VVG">VIOLENCE BASE SUR LE GENRE</Select.Option>
                <Select.Option value="GROSSESSE_PRECOSE">GROSSESSE PRECOSE</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Auteur"
              name="author"
              rules={
                [
                  { required: true, message: "Veuillez entrer le nom de l'auteur" },
                  {type:"string", message:"Entrer un libelle !"}
                ]}
              hasFeedback>
              <Input />
            </Form.Item>
            <Form.Item
              label="Contenu"
              name="contenu">
              <Editor
                  editorState={editorState}
                  onEditorStateChange={setEditorState}
                  toolbar={
                    {
                      options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'link', 'remove', 'history'],
                      inline: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace', 'superscript', 'subscript'],
                      },
                      blockType: {
                        inDropdown: false,
                        options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                      },
                      fontSize: {
                        component: FontSizePicker,
                        onChange: handleFontSizeChange,
                      },
                      fontFamily: {
                        component: FontFamilyPicker,
                        onChange: handleFontChange,
                      },
                      list: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ['unordered', 'ordered', 'indent', 'outdent'],
                        /* unordered: { icon: unordered, className: undefined },
                        ordered: { icon: ordered, className: undefined },
                        indent: { icon: indent, className: undefined },
                        outdent: { icon: outdent, className: undefined }, */
                      },
                      textAlign: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ['left', 'center', 'right', 'justify'],
                      },
                      remove: { /* icon: eraser,  */className: undefined, component: undefined },
                      history: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ['undo', 'redo'],
                        undo: { /* icon: undo, */ className: undefined },
                        redo: { /* icon: redo, */ className: undefined },
                      },
                    }
                  }
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class"
                  toolbarClassName="toolbar-class"
              />
            </Form.Item>
            <Form.Item
                label="Fichier"
                hasFeedback>
              <input type="file" accept="image/png" onChange={getFiles}/>
            </Form.Item>
          <Form.Item>

            <Space style={{float:'right'}}>
              <Button type="primary" htmlType="submit" loading={loading}>
                Submit
              </Button>
              <Button danger loading={loading} onClick={onClose} >
                <i className="fa fa-times" aria-hidden="true"></i> cancel
              </Button>

            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}