import {useNavigate, useParams} from "react-router-dom";
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";
import React, {useEffect, useState} from "react";
import {Empty, message, Spin} from "antd";
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {useSelector} from "react-redux";
import {EditorState, convertFromRaw} from "draft-js";
import './View.css';
import {useRole, useTitle} from "../../../components/hook/index";
import BreadCom from "../../../components/breadCom/BreadCom";

export default function ViewArticle() {
    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    );
    const {accessToken} = useSelector(state => state.authUser);
    const params = useParams();
    const nav = useNavigate();
    const [temoignage, setTemoignage] = useState(null);
    const [loading, setLoading] = useState(true);
    useRole(['ROOT','ADMIN','SUPERADMIN']);
    useTitle('View Articles');
    const navigation = (link)=>{
        setLoading(true);
        nav(link);
        setLoading(false);
    }
    const getTemoignageById = () => {
        axiosInstance(accessToken).get('api/article/find-by-id/'+parseInt(params.id))
            .then(
                (response)=>{
                    setTemoignage(response.data);
                    setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(response.data.contenu))));
                    setLoading(false);
                }
            ).catch(
            (error)=>{
                message.error(error.response.data.message);
                setLoading(false);
            }
        )
    }

    useEffect(
        ()=>{
            setLoading(true)
            getTemoignageById();
        },[]
    )
    const returnValue = temoignage ==null ? <>
        <Spin spinning={loading}>
            <Empty />
        </Spin>
    </>  : <>
        <Spin  spinning={loading}>
            <div style={{background: '#ffffff00'}}>
                <label>
                    <strong>Titre :</strong> <span style={{fontSize: '24px', padding: "5px", marginTop: '5px'}}>{temoignage.title}</span>
                </label>
                <hr />
                <label>
                    <strong>Auteur :</strong><span style={{fontSize: '24px', padding: "5px", marginTop: '5px'}}>{temoignage.author == null ? "Anonymous" : temoignage.author}</span>
                </label>
                <hr />
                <label>
                    <strong>Date :</strong><span style={{fontSize: '24px', padding: "5px", marginTop: '5px'}}>{temoignage.date == null ? "Anonymous" : temoignage.date}</span>
                </label>
                <hr />
                <label>
                    <strong>Contenu :</strong>
                </label>
                <div style={{padding: "5px", marginTop: '5px', width: '100%'}}>
                    <Editor
                        toolbarClassName="toolbar-class1"
                        readOnly={true}
                        editorState={editorState}
                        toolbar={
                            {
                                options: []
                            }
                        }
                    />
                </div>
            </div>
        </Spin>
    </>

    return(
        <>
            <BreadCom name={"Article info"}/>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <section className="col-lg-12">
                            <div className="card card-primary" style={{background: '#ffffff00'}}>
                                <div className="card-header">
                                    <h3 className="card-title">
                                        <i className="fas fa-chart-pie mr-1"></i>
                                        Detail
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <div className="tab-content p-0">
                                        {returnValue}
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                </div>
            </section>
        </>
    );
}