import {LogoutOutlined, MenuOutlined, MessageOutlined} from "@ant-design/icons";
import {useEffect} from "react";
import {useSelector} from "react-redux";

export default function NavBar() {
    const {accessToken} = useSelector(state => state.authUser);
    useEffect(
        ()=>{
            if(accessToken==null){
                window.location.href='/';
            }

        },[]
    );
    const deconnect = ()=>{
        window.location.href='/';
    }
    return(
        <>
            <nav className="main-header navbar navbar-expand navbar-white navbar-light" style={
                {
                    position: 'sticky',
                    top: '0px',
                    zIndex: 99
                }
            }>
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i ><MenuOutlined /></i></a>
                    </li>
                </ul>

                <ul className="navbar-nav ml-auto" >
                    <li className="nav-item dropdown" title="LogOut" >
                        <a className="nav-link" data-toggle="dropdown" onClick={()=>{deconnect()}} >
                            <i className=""><LogoutOutlined /></i>

                        </a>
                    </li>
                </ul>
            </nav>
        </>
    );

}