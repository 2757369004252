import React, {useEffect, useState} from "react";
import {message, Tag} from "antd";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {useRole, useTitle} from "../../../components/hook";
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";
import TableTemplate from "../../../components/table";

export default function ListGroupRoles(){
    const {accessToken} = useSelector(state => state.authUser);
    const param = useParams();
    const [loadingGU, setLoadingGU] = useState(true);
    const [role, setRole] = useState([]);
    useRole(['ROOT','SUPERADMIN']);
    useTitle('Liste des Roles');
    const fetchUsers = () => {
        try {
            setLoadingGU(true);
            axiosInstance(accessToken).get('api/groupes/roles').then(
                (responses)=>{
                    setRole(responses.data);
                    setLoadingGU(false);
                }).catch(
                error=>{
                    message.error(error.response.data.message)
                    setLoadingGU(false);
                }
            );
        } catch (error) {
            setLoadingGU(false);
        }
    };
    useEffect(() => {
        fetchUsers();
    }, []);
    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Nom',
            key: 'nom',
            render:  (_, record) => (
                <>
          <span>
            {record.nom}
          </span>
                </>
            ),
        },
        {
            title: 'Description',
            key: 'description',
            render:  (_, record) => (
                <>
          <span>
            {record.description}
          </span>
                </>
            ),
        },
    ];
    return (
        <>
            <h1 style={{marginTop: '15px'}}>Liste des Roles</h1>
            <TableTemplate columns={columns} loading={loadingGU} datas={role} />
        </>
    );

}