import {useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useRole, useTitle} from "../../../components/hook";
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";
import {Button, Space, Tag, Tooltip} from "antd";
import {EditFilled, InfoCircleFilled} from "@ant-design/icons";
import {Active} from "../../../components/active";
import BreadCom from "../../../components/breadCom/BreadCom";
import TableTemplate from "../../../components/table";
import AddFormation from "../AddFormations/AddFormation";
import {AddQuizzToFormation} from "../../../components/addQuizzToFormation/AddQuizzToFormation";
import {
    SetQuizzToFormationQuestionResponse
} from "../../../components/setQuizzToFormationQuestionResponse/SetQuizzToFormationQuestionResponse";

export default function ListAllCourses() {
    const {accessToken} = useSelector(state => state.authUser);
    const [loading, setLoading] = useState(true);
    const nav = useNavigate();
    const [formations, setFormations] = useState([]);
    const [quizz, setQuizz] = useState([]);
    useRole(['ROOT','ADMIN','SUPERADMIN']);
    useTitle('Liste des Formations');
    const navigate= (link)=>{
        nav(link);
    };
    const fetchFormations = () => {
        setLoading(true);
        axiosInstance(accessToken).get('test/api/formations/all').then(
            (response)=>{
                setFormations(response.data);
                setLoading(false);
            }
        ).catch( (error)=>{
            setLoading(false);
        });
    };
    const fetchQuizzAvailable = () => {
        setLoading(true);
        axiosInstance(accessToken).get('test/api/sondage/formation').then(
            (response)=>{
                setQuizz(response.data);
            }
        ).catch( (error)=>{
        });
    }
    useEffect(() => {
        fetchQuizzAvailable();
        fetchFormations();
    }, []);

    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
        },
        { title: 'Libelle',
            key: 'libelle',
            render:  (_, record) => (
                <>
          <span>
            {record.libelle}
          </span>
                </>
            ),
        },
        { title: 'Author',
            key: 'author',
            render:  (_, record) => (
                <>
          <span>
            {record.author}
          </span>
                </>
            ),
        },
        { title: 'Categorie',
            key: 'categorie',
            render:  (_, record) => (
                <>
          <span>
            {record.categorie}
          </span>
                </>
            ),
        },
        { title: 'Commentaires',
            key: 'comments',
            render:  (_, record) => (
                <>
          <span>
            {record.comments==null ? "0" : record.comments.length}
          </span>
                </>
            ),
        },
        { title: 'Chapitres',
            key: 'chapitres',
            render:  (_, record) => (
                <>
          <span>
            {record.chapitres==null ? "0" : record.chapitres.length}
          </span>
                </>
            ),
        },

        {
            title: 'Status',
            key: 'status',
            render:  (_, record) => {
                let result = null;
                if(record.status){
                    result = (
                        <Tag color="green" key={record.id} >
                            Activer
                        </Tag>
                    )
                }else if(!record.status){
                    result = (
                        <Tag color="volcano"  key={record.id}>
                            Desactiver
                        </Tag>
                    )
                }else {
                    result = (
                        <Tag color="volcano"  key={record.id}>
                            Unknow
                        </Tag>
                    )
                }
                return (
                    <>
                        {result}
                    </>
                )
            } ,
        },
        {
            title: 'Action',
            key: 'action',
            render:  (_, record) => {
                return  (
                    <>
                        <Space>
                            {record.quizz === null && <AddQuizzToFormation formation={record} quizz={quizz} />}
                            {record.quizz !== null && !record.configure && <SetQuizzToFormationQuestionResponse formation={record} />}
                            <Tooltip title={"Mise a jour d'une Formation"}>
                                <Button type="primary" shape="circle"  onClick={() => navigate('/admin/formation/update/'+record.id)} >
                                    <i aria-hidden="true"><EditFilled /></i>
                                </Button>
                            </Tooltip>
                            <Active status={record.status} path={record.status ? "test/api/formations/disable/":"test/api/formations/enable/"} id={record.id}/>
                        </Space>
                    </>
                )
            }
        },
    ];

    return(
        <>
            <BreadCom name={"Liste des Formations"}/>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <section className="col-lg-12">
                            <div className="card card-primary" style={{background: '#ffffff00'}}>
                                <div className="card-header">
                                    <h3 className="card-title">
                                        <i className="fas fa-chart-pie mr-1"></i>
                                        Liste des Formations
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <div className="tab-content p-0">
                                        <TableTemplate columns={columns} loading={loading} addBTN={<AddFormation />} datas={formations} />
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                </div>
            </section>
        </>
    );


}