import React, { useState, useEffect } from 'react';
import {Button, Form, Input, Modal, Space, message, Upload, Select, Spin, Empty, Breadcrumb, Row, Col} from 'antd';
import {EditorState, convertToRaw, RichUtils, convertFromRaw} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../add/Add.css';
import { useSelector } from "react-redux";
import FontSizePicker from '../toolbar/FontSize';
import FontFamilyPicker from '../toolbar/FontFamilyPicker';
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";
import {useNavigate, useParams} from "react-router-dom";
import {useRole, useTitle} from "../../../components/hook/index";
import BreadCom from "../../../components/breadCom/BreadCom";


export default function UpdateArticle({action, style, icon}) {
  useRole(['ROOT','ADMIN','SUPERADMIN']);
  const [editorState, setEditorState] = useState(
      () => EditorState.createEmpty(),
  );
  const handleSelectChange = (value) => {
    setSelectedValue(value);
  };
  useTitle('MAJ Articles');
  const [form] = Form.useForm();
  const [selectedValue, setSelectedValue] = useState(null);
  const {accessToken} = useSelector(state => state.authUser);
  const params = useParams();
  const nav = useNavigate();
  const [temoignage, setTemoignage] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigation = (link)=>{
    setLoading(true);
    nav(link);
    setLoading(false);
  }
  const getTemoignageById = () => {
    axiosInstance(accessToken).get('api/article/find-by-id/'+parseInt(params.id))
        .then(
            (response)=>{
              setTemoignage(response.data);
              setSelectedValue(response.data.categorie)
              setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(response.data.contenu))));
              setLoading(false);
            }
        ).catch(
        (error)=>{
          message.error(error.response.data.message);
          setLoading(false);
        }
    )
  }

  useEffect(
      ()=>{
        setLoading(true)
        getTemoignageById();
      },[]
  )
  const handleFontSizeChange = (fontSize) => {
    const newEditorState = RichUtils.toggleInlineStyle(editorState, `FONT_SIZE_${fontSize}`);
    setEditorState(newEditorState);
  };  
  const handleFontChange = (selectedFont) => {
    const newEditorState = RichUtils.toggleInlineStyle(editorState, `FONT_${selectedFont}`);
    setEditorState(newEditorState);
  };
  const onUpdate = async (values) => {
    setLoading(true);
    const dat ={
      "id":temoignage.id,
      "title":values.title,
      "author":values.author,
      "contenu":JSON.stringify(convertToRaw(editorState.getCurrentContent())),
      "categorie":selectedValue,
      "typeDonne":"ARTICLE",
      "status":temoignage.status,
      "phote":temoignage.phote,
      "lue":temoignage.lue,
    }
    axiosInstance(accessToken).put(
        "api/article/"+temoignage.id,
        dat,
    ).then((result)=> {
      message.success("Done ...");
      navigation('/admin/article');
      setLoading(false);
    }).catch((result)=>{
      setLoading(false);
      message.error(result.data.message)
    });
  }
  const returnValue = temoignage ==null ? <>
    <Spin spinning={loading}>
      <Empty />
    </Spin>
  </>  : <>
    <Spin  spinning={loading}>

      <div >
        <Row >
          <Col>

            <Row style={{marginTop: '15px'}}>
              <Col >
                <Form onFinish={onUpdate} form={form} initialValues={temoignage}>
                  <Form.Item
                      label="Titre"
                      name="title"
                      rules={
                        [
                          { required: true, message: 'Veuillez entrer le titre' },
                          {type:"string", message:""}
                        ]}
                      hasFeedback>
                    <Input />
                  </Form.Item>
                  <Form.Item
                      label="CATEGORIE"
                      name="categorie">
                    <Select placeholder="Choisir la Categorie" value={selectedValue} onChange={handleSelectChange}>
                      <Select.Option value="SANTE_SEXUELLE">SANTE SEXUELLE</Select.Option>
                      <Select.Option value="IVG">INTERRUPTION VOLONTAIRE GROSSESE</Select.Option>
                      <Select.Option value="PLANNING_FAMILLIAL">PLANNING FAMILLIAL</Select.Option>
                      <Select.Option value="VIH_IST">VIH/IST</Select.Option>
                      <Select.Option value="HYGENE_MENSTRUELLE">HYGENE MENSTRUELLE</Select.Option>
                      <Select.Option value="VVG">VIOLENCE BASE SUR LE GENRE</Select.Option>
                      <Select.Option value="GROSSESSE_PRECOSE">GROSSESSE PRECOSE</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                      label="Auteur"
                      name="author"
                      rules={
                        [
                          { required: true, message: "Veuillez entrer le nom de l'auteur" },
                          {type:"string", message:"Entrer un libelle !"}
                        ]}
                      hasFeedback>
                    <Input />
                  </Form.Item>
                  <Form.Item
                      label="Contenu"
                      name="contenu">
                    <Editor
                        editorState={editorState}
                        onEditorStateChange={setEditorState}
                        toolbar={
                          {
                            options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'link', 'remove', 'history'],
                            inline: {
                              inDropdown: false,
                              className: undefined,
                              component: undefined,
                              dropdownClassName: undefined,
                              options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace', 'superscript', 'subscript'],
                            },
                            blockType: {
                              inDropdown: false,
                              options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
                              className: undefined,
                              component: undefined,
                              dropdownClassName: undefined,
                            },
                            fontSize: {
                              component: FontSizePicker,
                              onChange: handleFontSizeChange,
                            },
                            fontFamily: {
                              component: FontFamilyPicker,
                              onChange: handleFontChange,
                            },
                            list: {
                              inDropdown: false,
                              className: undefined,
                              component: undefined,
                              dropdownClassName: undefined,
                              options: ['unordered', 'ordered', 'indent', 'outdent'],
                              /* unordered: { icon: unordered, className: undefined },
                              ordered: { icon: ordered, className: undefined },
                              indent: { icon: indent, className: undefined },
                              outdent: { icon: outdent, className: undefined }, */
                            },
                            textAlign: {
                              inDropdown: false,
                              className: undefined,
                              component: undefined,
                              dropdownClassName: undefined,
                              options: ['left', 'center', 'right', 'justify'],
                            },
                            remove: { /* icon: eraser,  */className: undefined, component: undefined },
                            history: {
                              inDropdown: false,
                              className: undefined,
                              component: undefined,
                              dropdownClassName: undefined,
                              options: ['undo', 'redo'],
                              undo: { /* icon: undo, */ className: undefined },
                              redo: { /* icon: redo, */ className: undefined },
                            },
                          }
                        }
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        toolbarClassName="toolbar-class"
                    />
                  </Form.Item>

                  <Form.Item>
                    <Space style={{float:'right'}}>
                      <Button type='primary' htmlType='submit' disabled={loading}>
                        Submit
                      </Button>
                      <Button danger  disabled={loading}  onClick={() => navigation('/admin/article')} >
                        <i className="fa fa-times" aria-hidden="true"></i> cancel
                      </Button>

                    </Space>
                  </Form.Item>
                </Form>
              </Col>
            </Row>

          </Col>
        </Row>
        <div>
        </div>


      </div>
    </Spin>
  </>

  return (
      <>
        <BreadCom name={"Article update"}/>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <section className="col-lg-12">
                <div className="card card-primary" style={{background: '#ffffff00'}}>
                  <div className="card-header">
                    <h3 className="card-title">
                      <i className="fas fa-chart-pie mr-1"></i>
                      Update
                    </h3>
                  </div>
                  <div className="card-body">
                    <div className="tab-content p-0" style={
                      {
                        alignItems: 'center',
                        justifyContent: 'center'
                      }
                    }>
                      {returnValue}
                    </div>
                  </div>
                </div>
              </section>

            </div>
          </div>
        </section>
      </>
  );

}