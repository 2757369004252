import {LockOutlined, MailOutlined} from "@ant-design/icons";
import {useTitle} from "../../components/hook";
import {useDispatch} from "react-redux";
import {useEffect, useState} from "react";
import {toastClick} from "../../components/toast/CustomToast";
import {loginUser, logOutUser} from "../../configurations/features/authSliceUser";
import Loader from "../../components/loader/Loader";

export default function Login(){
    useTitle("Login");
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [login, setLogin] = useState(
        {
            login: '',
            password: ''
        }
    );
    const [active, setActive] = useState(
        {
            login: false,
            password: false
        }
    );


    const checkPassWordValue = (event) => {
        let pwd = event.target.value.toString().trim();
        if (pwd.length < 3) {
            document.getElementById('pwd').style.border= '1px solid #f20c0c';
            active.password=false;
        } else {
            login.password = pwd;
            setLogin(login);
            document.getElementById('pwd').style.border= '1px solid green';
            active.password=true;
        }
        setActive(active)
    }

    const checkLoginValue = (event) => {
        let loginName = event.target.value.toString().trim();
        if (loginName.length < 3) {
            document.getElementById('login').style.border= '1px solid #f20c0c';
            active.login=false;
        }else if(!loginName.includes("@")){
            document.getElementById('login').style.border= '1px solid #f20c0c';
            active.login=false;
        } else {
            login.login = loginName;
            setLogin(login);
            document.getElementById('login').style.border= '1px solid green';
            active.login=true;
        }
        setActive(active)
    }

    const submitRegisterForm = (event) => {
        event.preventDefault();
        setLoading(true);

        if (Object.values(active).every(value => value)) {
            dispatch(loginUser(login.login, login.password));
            setLoading(false);
        } else {
            toastClick("Champs vide","info");
            setLoading(false);
        }
    };

    useEffect(
        ()=>{
            setLoading(true);
            dispatch(logOutUser());
            setLoading(false);
        },[]
    );
    return(<>
        {/*<Loader view={loading} />*/}
        <div className="hold-transition login-page">
            <div className="login-box">
                <div className="login-logo">
                    <a><b>YOUTH</b>FP</a>
                </div>
                <div className="card">
                    <div className="card-body login-card-body">

                        <form  onSubmit={submitRegisterForm}>
                            <div className="input-group mb-3">
                                <input type="email" className="form-control" id="login" onKeyUp={checkLoginValue} placeholder="Email" />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span><MailOutlined /></span>
                                    </div>
                                </div>
                            </div>
                            <div className="input-group mb-3">
                                <input type="password" className="form-control" id="pwd" onKeyUp={checkPassWordValue} placeholder="Password" />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span><LockOutlined /></span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <button type="submit" className="btn btn-primary btn-block">Connexion</button>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    </>);
}