import React, { useState, useEffect } from 'react';
import {Button, Form, Input, Modal, Space, message, Upload, Select} from 'antd';
import {PlusCircleFilled, UploadOutlined} from "@ant-design/icons";
import { EditorState, convertToRaw, RichUtils } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../article/add/Add.css';
import { useSelector } from "react-redux";
import { convertToHTML } from 'draft-convert';
import FontSizePicker from '../../article/toolbar/FontSize';
import FontFamilyPicker from '../../article/toolbar/FontFamilyPicker';
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";
import {useRole} from "../../../components/hook";


export default function AddAPropos({action, style}) {
  useRole(['ROOT','ADMIN','SUPERADMIN']);
  const {accessToken} = useSelector(state => state.authUser);
  const [editorState, setEditorState] = useState(
    () => EditorState.createEmpty()
  );
  const handleFontSizeChange = (fontSize) => {
    const newEditorState = RichUtils.toggleInlineStyle(editorState, `FONT_SIZE_${fontSize}`);
    setEditorState(newEditorState);
  };  
  const handleFontChange = (selectedFont) => {
    const newEditorState = RichUtils.toggleInlineStyle(editorState, `FONT_${selectedFont}`);
    setEditorState(newEditorState);
  };
  const [convertedContent, setConvertedContent] = useState(null);

  useEffect(() => {
    let html = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(html);
  }, []);


  const handleEditorChange = newEditorState => {
    setEditorState(newEditorState);
  };
  const [modalAddOpen, setModalAddOpen] = useState(false);
  const [loading, setLoading] = useState(false);
const openModal =()=>{
  setEditorState(() => EditorState.createEmpty());
  setLoading(false);
  setModalAddOpen(true)
}
  const onClose = () => {
    setLoading(true)
    setModalAddOpen(false);

  }
  const onSubmit = async (values) => {
    if(editorState.getCurrentContent().getPlainText().trim().length!=0){
      setLoading(true);
      const dat ={
        "id":null,
        "contenu":JSON.stringify(convertToRaw(editorState.getCurrentContent())),
      }
      axiosInstance(accessToken).post(
          "api/apropos/save",
          dat
      ).then((result)=> {
        message.info("Done ...");
        setModalAddOpen(false);
        window.location.reload();
        setLoading(false);
      }).catch((result)=>{
        setLoading(false);
        message.error(result.response.data.error)
      });
    }else{
      message.error("Le contenu est vide")
    }
  }

  return(
    <>
      <Button style={{float: "right"}}  onClick={openModal} >
        <i aria-hidden="true"><PlusCircleFilled /></i> {action}
          </Button>
          <Modal
          title="A Propos"
          style={{
            top: 20,
          }}
          width={1000}
          open={modalAddOpen}
          maskClosable={false}
          closable={false}
          footer={[]}
        >
          <Form onFinish={onSubmit}>
            <Form.Item
              label="Contenu"
              name="contenu"
              hasFeedback>
              <Editor
                  editorState={editorState}
                  onEditorStateChange={setEditorState}
                  toolbar={
                    {
                      options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'link', 'remove', 'history'],
                      inline: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace', 'superscript', 'subscript'],
                      },
                      blockType: {
                        inDropdown: false,
                        options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                      },
                      fontSize: {
                        component: FontSizePicker,
                        onChange: handleFontSizeChange,
                      },
                      fontFamily: {
                        component: FontFamilyPicker,
                        onChange: handleFontChange,
                      },
                      list: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ['unordered', 'ordered', 'indent', 'outdent'],
                        /* unordered: { icon: unordered, className: undefined },
                        ordered: { icon: ordered, className: undefined },
                        indent: { icon: indent, className: undefined },
                        outdent: { icon: outdent, className: undefined }, */
                      },
                      textAlign: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ['left', 'center', 'right', 'justify'],
                      },
                      remove: { /* icon: eraser,  */className: undefined, component: undefined },
                      history: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ['undo', 'redo'],
                        undo: { /* icon: undo, */ className: undefined },
                        redo: { /* icon: redo, */ className: undefined },
                      },
                    }
                  }
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class"
                  toolbarClassName="toolbar-class"
              />
            </Form.Item>
          <Form.Item>

            <Space style={{float:'right'}}>
              <Button type="primary" htmlType="submit" loading={loading}>
                Submit
              </Button>
              <Button danger loading={loading} onClick={onClose} >
                <i className="fa fa-times" aria-hidden="true"></i> cancel
              </Button>

            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}