import { configureStore } from '@reduxjs/toolkit';
import authSlice from "../features/authSliceUser";


const store = configureStore({
    reducer: {
        authUser: authSlice,
    },
});

export default store