import {useSelector} from "react-redux";
import React, {useState} from "react";
import {Button, Form, Input, message, Modal, Select, Space, Spin} from "antd";
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";
import {useRole} from "../../../components/hook";

export default function AddChapter({id}) {
    const {accessToken} = useSelector(state => state.authUser);
    const [modalAddOpen, setModalAddOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    useRole(['ROOT','ADMIN','SUPERADMIN']);


    const openModal =()=>{
        form.resetFields();
        setLoading(false)
        setModalAddOpen(true)
    }
    const onSubmit = (values) => {
        setLoading(true);
        const dat={
            id: null,
            title: values.title,
            status: true,
            paragraphes: []
        };
        axiosInstance(accessToken).post(
            "test/api/chapter/"+id,
            dat
        ).then(
            (result)=> {
                message.success('Chapitre '+dat.title+' Create');
                form.resetFields();
                setLoading(false);
                setModalAddOpen(false);
                setTimeout(
                    ()=>{
                        window.location.reload();
                    },2000
                )
            }).catch((result)=> {
            message.error(result.response.data.message);
            setLoading(false);
        });
    };

    return(
        <>
            <Button style={{float: "right"}}  onClick={openModal} >
                <i className="fa fa-plus" aria-hidden="true"></i>Ajouter
            </Button>
            <Modal
                title="Creation d'un Chapitre"
                style={{
                    top: 20,
                }}
                width={1000}
                open={modalAddOpen}
                maskClosable={false}
                closable={false}
                footer={[]}
            >
                <Spin spinning={loading}>
                    <Form onFinish={onSubmit} form={form}>
                        <Form.Item
                            label="Titre"
                            name="title"
                            rules={
                                [
                                    { required: true, message: 'Veuillez entrer le Titre' },
                                    {type:"string", message:"Entrer un titre valide !"}
                                ]}
                            hasFeedback>
                            <Input placeholder="entrer le Titre"/>
                        </Form.Item>
                        <Form.Item>
                            <Space style={{float:'right'}}>
                                <Button type='primary' htmlType='submit'>
                                    Submit
                                </Button>
                                <Button danger  disabled={loading}  onClick={() => setModalAddOpen(false)} >
                                    <i className="fa fa-times" aria-hidden="true"></i> cancel
                                </Button>

                            </Space>
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
        </>
    );
}