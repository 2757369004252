import axios from "axios";
import { baseUrl } from "../baseUrl/BaseUrl";

// Créez un nouvel objet Axios avec les configurations de base
const axiosInstance = (accessToken) => {
    const axiosInstance = axios.create({
        baseURL: baseUrl,
        withCredentials: false,
        headers: {
            Authorization: accessToken ? `Bearer ${accessToken}` : "",
        },
    });

    // Intercepteur si nécessaire...

    return axiosInstance;
};
export default axiosInstance;