import BreadCom from "../../components/breadCom/BreadCom";
import {FileTextOutlined, GlobalOutlined, HomeOutlined, UsergroupAddOutlined, UserOutlined} from "@ant-design/icons";
import {ARTICLE, DENONCIATION, USER} from "../../components/utils/Constantes";
import {useSelector} from "react-redux";
import {useRole, useTitle} from "../../components/hook";
import {useEffect, useState} from "react";
import axiosInstance from "../../configurations/axiosInstance/AxiosInstance";

export default function DashBoard(){
    const {user, accessToken, droits} = useSelector(state => state.authUser);
    const[loading, setLoading] = useState(true);
    const[data, setData] = useState({});
    useRole(['ROOT','SUPERADMIN']);
    useTitle('Dashboard');
    const getStatistique = async ()=>{
        setLoading(true);
        await axiosInstance(accessToken).get(
            "user/dashboard"
        ).then(
            (response)=>{
                let user = response.data.user;
                let dataResponse = response.data;
                if(typeof response.data.user === "number"){
                    dataResponse.user = parseInt(user+400).toString();
                }else {
                    dataResponse.user = '0';
                }
                setData(dataResponse)
                setLoading(false);
            }
        ).catch(
            (error)=>{
                setLoading(false);
            }
        );
    }
    useEffect(() => {
        getStatistique();
    }, []);
    return(
        <>
            <BreadCom name={"DashBoard"}/>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-3 col-6">
                            <div className="small-box bg-info">
                                <div className="inner">
                                    <h3>{data.user}</h3>

                                    <p>Utilisateurs</p>
                                </div>
                                <div className="icon">
                                    <i className=""><UsergroupAddOutlined /></i>
                                </div>
                                <a href={USER} className="small-box-footer">Plus d'information</a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6">
                            <div className="small-box bg-success">
                                <div className="inner">
                                    <h3>{data.cp}</h3>

                                    <p>Centres Partenaires</p>
                                </div>
                                <div className="icon">
                                    <i className=""><HomeOutlined /></i>
                                </div>
                                <a href="#" className="small-box-footer">Plus d'information</a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6">
                            <div className="small-box bg-warning">
                                <div className="inner">
                                    <h3>{data.article}</h3>

                                    <p>Articles</p>
                                </div>
                                <div className="icon">
                                    <i className=""><FileTextOutlined /></i>
                                </div>
                                <a href={ARTICLE} className="small-box-footer">Plus d'information</a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6">
                            <div className="small-box bg-danger">
                                <div className="inner">
                                    <h3>{data.denonciation}</h3>

                                    <p>Denonciations</p>
                                </div>
                                <div className="icon">
                                    <i className=""><GlobalOutlined /></i>
                                </div>
                                <a href={DENONCIATION} className="small-box-footer">Plus d'information</a>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <section className="col-lg-12 connectedSortable">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">
                                        <i className="fas fa-chart-pie mr-1"></i>
                                        Sales
                                    </h3>
                                    <div className="card-tools">
                                        <ul className="nav nav-pills ml-auto">
                                            <li className="nav-item">
                                                <a className="nav-link active" href="#revenue-chart"
                                                   data-toggle="tab">Area</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" href="#sales-chart"
                                                   data-toggle="tab">Donut</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="tab-content p-0">
                                        <div className="chart tab-pane active" id="revenue-chart"
                                             style={{position: "relative",height:"300px"}}>
                                            <canvas id="revenue-chart-canvas" height="300"
                                                    style={{height:"300px"}}></canvas>
                                        </div>
                                        <div className="chart tab-pane" id="sales-chart"
                                             style={{position:"relative", height:"300px"}}>
                                            <canvas id="sales-chart-canvas" height="300"
                                                    style={{height:"300px"}}></canvas>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                </div>
            </section>
        </>
    );
}