import React, {useEffect, useState} from 'react';
import {Button, Form, Input, message, Modal, Select, Space, Spin} from 'antd';
import { useSelector } from "react-redux";
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";
import {PlusCircleFilled} from "@ant-design/icons";
import {useRole} from "../../../components/hook";

export default function AddTheme() {
    const {accessToken} = useSelector(state => state.authUser);
    const [modalAddOpen, setModalAddOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    useRole(['ROOT','ADMIN','SUPERADMIN']);
    const openModal = () => {
        form.resetFields();
        setModalAddOpen(true)
        setLoading(false)
    }

    const onSubmit = (values) => {
        setLoading(true);

        const dat = {
            id:null,
            title:values.title,
            active:false
        };
        axiosInstance(accessToken).post(
            "api/theme",
            dat,
        ).then(
            (result) => {
                message.success('Theme ' + dat.title + ' Create');
                form.resetFields();
                setLoading(false);
                window.location.reload();
                setModalAddOpen(false);
            }).catch((result) => {
            message.error(result.response.data.message);
            setLoading(false);
        });
    };

    return(
        <>
            <Button style={{float: "right"}}  onClick={openModal} >
                <i aria-hidden="true"><PlusCircleFilled /></i>Ajouter
            </Button>
            <Modal
                title="Creation d'un Theme"
                style={{
                    top: 20,
                }}
                width={1000}
                open={modalAddOpen}
                maskClosable={false}
                closable={false}
                footer={[]}
            >
                <Spin spinning={loading}>
                    <Form onFinish={onSubmit} form={form}>
                        <Form.Item
                            label="Titre"
                            name="title"
                            rules={
                                [
                                    { required: true, message: 'Veuillez entrer le titre' },
                                    {type:"string", message:"Entrer un titre valide !"}
                                ]}
                            hasFeedback>
                            <Input placeholder="entrer le titre"/>
                        </Form.Item>
                        <Form.Item>
                            <Space style={{float:'right'}}>
                                <Button type='primary' htmlType='submit' disabled={loading}>
                                    Submit
                                </Button>
                                <Button danger  disabled={loading}  onClick={() => setModalAddOpen(false)} >
                                    <i className="fa fa-times" aria-hidden="true"></i> cancel
                                </Button>

                            </Space>
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
        </>
    );

}