import React, {useEffect, useState} from "react";
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";
import {Empty, message, Spin, Tag} from "antd";
import {useParams} from "react-router-dom";
import {useRole, useTitle} from "../../../components/hook";
import {useSelector} from "react-redux";

export default function ListGroupRolesAdd(){
    const param = useParams();
    const [loading, setLoadingGU] = useState(true);
    const [roles, setRole] = useState([]);
    const [groupRoles, setRoleG] = useState([]);
    const {accessToken} = useSelector(state => state.authUser);
    let call = false;
    useRole(['ROOT','SUPERADMIN']);
    useTitle('Ajout des Roles');
    const fetchRole = () => {
        axiosInstance(accessToken).get('api/groupes/roles').then(
            (responses)=>{
                setRole(responses.data);
            }).catch(
            error=>{
            }
        );
    };
    const addRole = (role) => {
        axiosInstance(accessToken).post('api/groupes/add-roles/'+parseInt(param.id), role).then(
            (responses)=>{
                message.success("Droit "+role.nom+" Ajoute");
                window.location.reload();
            }).catch(
            error=>{
                message.error(error.response.data.message);
                setLoadingGU(false);
            });
    };
    const setRoleInGroup = (role)=>{
        setLoadingGU(true);
        if(groupRoles.includes(role.id)) {
            removeRole(Array.of(role));
        }else {
            addRole(Array.of(role))
        }
    }
    const removeRole = (role) => {
        axiosInstance(accessToken).post('api/groupes/remove-roles/'+parseInt(param.id), role).then(
            (responses)=>{
                message.success("Droit "+role.nom+" retire");
                window.location.reload();
            }).catch(
            error=>{
                message.error(error.res.data.message);
                setLoadingGU(false);
            }
        );
    };
    const fetchGroup = () => {
        setLoadingGU(true);
        call = true;
        axiosInstance(accessToken).get('api/groupes/roles/'+parseInt(param.id)).then(
            (responses)=>{
                setRoleG([]);
                let role = [];
                for(let i in responses.data){
                    role.push(responses.data[i].id)
                }
                setRoleG(role);
                setLoadingGU(false);
            }).catch(
            error=>{
                message.error(error.response.data.message)
                setLoadingGU(false);
            }
        );
    };
    useEffect(() => {
        if(!call){
            fetchGroup();
            fetchRole();
        }
    }, [call]);
    let returnValue = loading ? (
        <Empty />
    ) : (<div>
        {
            roles != [] && roles.map((role, index)=>{
                return (   <>
                    <Tag title={groupRoles.includes(role.id) ? "Retirer le Droit" : "Ajouter le Droit"}
                         style={{cursor: 'pointer'}}
                         color={groupRoles.includes(role.id) ? "green" : "blue"}
                         key={role.id}
                         onClick={()=>setRoleInGroup(role)}>
                        {role.nom}
                    </Tag>
                </>);
            })
        }
    </div>);


    return (
        <>
            <Spin spinning={loading}>
                <h1 style={{marginTop: '15px'}}>Ajouter / retirer des Roles</h1>
                {returnValue}
            </Spin>
        </>
    );

}