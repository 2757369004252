export default function Footer(){
    return(
        <footer className="main-footer">
            <strong>Copyright &copy; 2023-2024 <a>YouthFP</a>.</strong>
            All rights reserved.
            <div className="float-right d-none d-sm-inline-block">
                <b>Version</b> 1.2.0
            </div>
        </footer>
    );
}