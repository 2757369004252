import {useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useRole, useTitle} from "../../../components/hook";
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";
import {Button, Space, Tag, Tooltip} from "antd";
import {EditFilled, InfoCircleFilled} from "@ant-design/icons";
import {Active} from "../../../components/active";
import BreadCom from "../../../components/breadCom/BreadCom";
import TableTemplate from "../../../components/table";
import AddSondage from "../add/AddSondage";
import {Archive} from "../../../components/archive";
import {Schedule} from "../../../components/Schedule";

export default function ListSondages(){
    const {accessToken, droits} = useSelector(state => state.authUser);
    const [current, setCurrent] = useState(0);
    const [loading, setLoading] = useState(true);
    const nav = useNavigate();
    const [sondages, setSondages] = useState([]);
    useRole(['ROOT','ADMIN','SUPERADMIN']);
    useTitle('Liste des Sondages');
    const navigate= (link)=>{
        nav(link);
    }

    const fetchSondages = ()=>{
        setLoading(true);
        axiosInstance(accessToken).get('test/api/sondage').then(
            (responses)=>{
                setSondages(responses.data);
                setLoading(false);
            }).catch(
            (error)=>{
                setLoading(false);
            }
        );
    }
    useEffect(() => {
        fetchSondages();
    }, []);

    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Name',
            key: 'name',
            render:  (_, record) => (
                <>
          <span>
            {record.name}
          </span>
                </>
            ),
        },
        {
            title: 'Cible',
            key: 'target',
            render:  (_, record) => (
                <>
          <span>
            {record.typeUser}
          </span>
                </>
            ),
        },
        {
            title: 'Nombre de Questions',
            key: 'questions',
            render:  (_, record) => (
                <>
          <span>
            {record.questions.length}
          </span>
                </>
            ),
        },
        {
            title: 'Status',
            key: 'state',
            render:  (_, record) => {
                let result = <Tag color="volcano"  key={record.id}>
                    {record.state}
                </Tag>;
                return (
                    <>
                        {result}
                    </>
                )
            } ,
        },
        {
            title: 'Action',
            key: 'action',
            render:  (_, record) => {
                const data = ()=>{
                    if(record.state === 'CREATED'){
                        return <>
                            <Tooltip title={"Detail sur le sondage"}>
                                <Button type="primary" shape="circle"  onClick={() => navigate('/admin/quizz/'+record.id)} >
                                    <i aria-hidden="true"><InfoCircleFilled /></i>
                                </Button>
                            </Tooltip>
{/*                            <Tooltip title={"Mise a jour d'un sondage"}>
                                <Button type="primary" shape="circle"  onClick={() => navigate('/admin/quizz/update/'+record.id)} >
                                    <i aria-hidden="true"><EditFilled /></i>
                                </Button>
                            </Tooltip>*/}
                            <Archive key={record.id+"active"} path={"test/api/sondage/update/"+record.id+"/ACTIVE"} text={"ACTIVE"}/>
                            <Schedule key={record.id+"schedule"} path={"test/api/sondage/update/"+record.id} text={"Schedule"}/>
                        </>;
                    }else if (record.state === 'SCHEDULED'){
                        return <>
                            <Tooltip title={"Detail sur le sondage"}>
                                <Button type="primary" shape="circle"  onClick={() => navigate('/admin/quizz/'+record.id)} >
                                    <i aria-hidden="true"><InfoCircleFilled /></i>
                                </Button>
                            </Tooltip>
                            {/*<Tooltip title={"Mise a jour d'un sondage"}>
                                <Button type="primary" shape="circle"  onClick={() => navigate('/admin/quizz/update/'+record.id)} >
                                    <i aria-hidden="true"><EditFilled /></i>
                                </Button>
                            </Tooltip>*/}
                            <Archive key={record.id+"active"} path={"test/api/sondage/update/"+record.id+"/ACTIVE"} text={"ACTIVE"}/>
                            <Archive key={record.id+"Archive"} path={"test/api/sondage/update/"+record.id+"/ARCHIVE"} text={"ARCHIVE"}/>
                        </>;
                    }else if (record.state === 'ACTIVE'){
                        return <>
                            <Tooltip title={"Detail sur le sondage"}>
                                <Button type="primary" shape="circle"  onClick={() => navigate('/admin/quizz/'+record.id)} >
                                    <i aria-hidden="true"><InfoCircleFilled /></i>
                                </Button>
                            </Tooltip>
                            <Archive key={record.id+"Archive"} path={"test/api/sondage/update/"+record.id+"/ARCHIVE"} text={"ARCHIVE"}/>
                        </>;
                    }else{
                        return <></>;
                    }
                };
                return  (
                    <>
                        <Space>
                            {data()}
                        </Space>
                    </>
                )
            }
        },
    ];

    return(
        <>
            <BreadCom name={"Liste des Sondages"}/>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <section className="col-lg-12">
                            <div className="card card-primary" style={{background: '#ffffff00'}}>
                                <div className="card-header">
                                    <h3 className="card-title">
                                        <i className="fas fa-chart-pie mr-1"></i>
                                        Liste des Sondages
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <div className="tab-content p-0">
                                        <TableTemplate columns={columns} loading={loading} addBTN={<AddSondage />} datas={sondages} />
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                </div>
            </section>
        </>
    );

}