import React, { useState, useEffect } from 'react';
import {Button, Form, Space, message, Spin, Empty, Row, Col} from 'antd';
import {EditorState, convertToRaw, RichUtils, convertFromRaw} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../article/add/Add.css';
import { useSelector } from "react-redux";
import FontSizePicker from '../../article/toolbar/FontSize';
import FontFamilyPicker from '../../article/toolbar/FontFamilyPicker';
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";
import {useNavigate, useParams} from "react-router-dom";
import {useRole, useTitle} from "../../../components/hook/index";
import BreadCom from "../../../components/breadCom/BreadCom";
import {toastClick} from "../../../components/toast/CustomToast";


export default function UpdateAPropos() {
    const [editorState, setEditorState] = useState(
    () => EditorState.createEmpty(),
);
    const handleSelectChange = (value) => {
        setSelectedValue(value);
    };
    useRole(['ROOT','SUPERADMIN']);
    useTitle('MAJ ');
    const [selectedValue, setSelectedValue] = useState(null);
    const {accessToken} = useSelector(state => state.authUser);
    const params = useParams();
    const nav = useNavigate();
    const [aPropos, setAPropos] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigation = (link)=>{
        setLoading(true);
        nav(link);
        setLoading(false);
    }

    const getAPropos = () => {
        axiosInstance().get(
            "api/apropos/list"
        ).then((result)=> {
            if(result.data != null){
                setAPropos(result.data)
                setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(result.data.contenu))));
            }
            setLoading(false);
        }).catch((result)=> {
            setLoading(false);
        });
    }
    useEffect(
        ()=>{
            setLoading(true)
            getAPropos();
        },[]
    )
    const handleFontSizeChange = (fontSize) => {
        const newEditorState = RichUtils.toggleInlineStyle(editorState, `FONT_SIZE_${fontSize}`);
        setEditorState(newEditorState);
    };
    const handleFontChange = (selectedFont) => {
        const newEditorState = RichUtils.toggleInlineStyle(editorState, `FONT_${selectedFont}`);
        setEditorState(newEditorState);
    };

    const onUpdate = async (values) => {
        if(editorState.getCurrentContent().getPlainText().trim().length!=0){
            setLoading(true);
            const dat ={
                "id":aPropos.id,
                "contenu":JSON.stringify(convertToRaw(editorState.getCurrentContent())),
            }
            axiosInstance(accessToken).put(
                "api/apropos/update/"+aPropos.id,
                dat
            ).then((result)=> {
                toastClick("Done ...","success");
                navigation('/admin/apropos');
                setLoading(false);
            }).catch((result)=>{
                setLoading(false);
                toastClick(result.response.data.error,"error");
            });
        }else{
            toastClick("Le contenu est vide","info");
        }
    }
    const returnValue = aPropos ==null ? <>
        <Spin spinning={loading}>
            <Empty />
        </Spin>
    </>  : <>
        <Spin  spinning={loading}>

            <div  style={{background: '#ffffff00'}}>
                <Row >
                    <Col>

                        <Row style={{marginTop: '15px'}}>
                            <Col >
                                <Form onFinish={onUpdate} >
                                    <Form.Item
                                        label="Contenu"
                                        name="contenu">
                                        <Editor
                                            editorState={editorState}
                                            onEditorStateChange={setEditorState}
                                            toolbar={
                                                {
                                                    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'link', 'remove', 'history'],
                                                    inline: {
                                                        inDropdown: false,
                                                        className: undefined,
                                                        component: undefined,
                                                        dropdownClassName: undefined,
                                                        options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace', 'superscript', 'subscript'],
                                                    },
                                                    blockType: {
                                                        inDropdown: false,
                                                        options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
                                                        className: undefined,
                                                        component: undefined,
                                                        dropdownClassName: undefined,
                                                    },
                                                    fontSize: {
                                                        component: FontSizePicker,
                                                        onChange: handleFontSizeChange,
                                                    },
                                                    fontFamily: {
                                                        component: FontFamilyPicker,
                                                        onChange: handleFontChange,
                                                    },
                                                    list: {
                                                        inDropdown: false,
                                                        className: undefined,
                                                        component: undefined,
                                                        dropdownClassName: undefined,
                                                        options: ['unordered', 'ordered', 'indent', 'outdent'],
                                                        /* unordered: { icon: unordered, className: undefined },
                                                        ordered: { icon: ordered, className: undefined },
                                                        indent: { icon: indent, className: undefined },
                                                        outdent: { icon: outdent, className: undefined }, */
                                                    },
                                                    textAlign: {
                                                        inDropdown: false,
                                                        className: undefined,
                                                        component: undefined,
                                                        dropdownClassName: undefined,
                                                        options: ['left', 'center', 'right', 'justify'],
                                                    },
                                                    remove: { /* icon: eraser,  */className: undefined, component: undefined },
                                                    history: {
                                                        inDropdown: false,
                                                        className: undefined,
                                                        component: undefined,
                                                        dropdownClassName: undefined,
                                                        options: ['undo', 'redo'],
                                                        undo: { /* icon: undo, */ className: undefined },
                                                        redo: { /* icon: redo, */ className: undefined },
                                                    },
                                                }
                                            }
                                            wrapperClassName="wrapper-class"
                                            editorClassName="editor-class"
                                            toolbarClassName="toolbar-class"
                                        />
                                    </Form.Item>

                                    <Form.Item>
                                        <Space style={{float:'right'}}>
                                            <Button type='primary' htmlType='submit' disabled={loading}>
                                                Submit
                                            </Button>
                                            <Button danger  disabled={loading}  onClick={() => navigation('/admin/apropos')} >
                                                <i className="fa fa-times" aria-hidden="true"></i> cancel
                                            </Button>

                                        </Space>
                                    </Form.Item>
                                </Form>
                            </Col>
                        </Row>

                    </Col>
                </Row>
                <div>
                </div>


            </div>
        </Spin>
    </>

    return (
        <>
            <BreadCom name={"Article update"}/>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <section className="col-lg-12">
                            <div className="card card-primary">
                                <div className="card-header">
                                    <h3 className="card-title">
                                        <i className="fas fa-chart-pie mr-1"></i>
                                        Update
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <div className="tab-content p-0" style={
                                        {
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }
                                    }>
                                        {returnValue}
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                </div>
            </section>
        </>
    );





}