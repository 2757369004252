import React from "react";
import {Result} from "antd";
import {useTitle} from "../hook";

export default function NoAccessAdminPage() {
    useTitle('403');
    return   <Result
        status="403"
        title="403"
        subTitle="Sorry, you are not authorized to access this page."
    />
}