import React from 'react';
import { Button, QRCode, Segmented, Space } from 'antd';

import logo from './logo192.svg';
function doDownload(url, fileName) {
    const a = document.createElement('a');
    a.download = fileName;
    a.href = url;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}
const downloadCanvasQRCode = () => {
    const canvas = document.getElementById('myqrcode')?.querySelector('canvas');
    if (canvas) {
        const url = canvas.toDataURL();
        doDownload(url, 'QRCode.png');
    }
};
const downloadSvgQRCode = () => {
    const svg = document.getElementById('myqrcode')?.querySelector('svg');
    const svgData = new XMLSerializer().serializeToString(svg);
    const blob = new Blob([svgData], {
        type: 'image/svg+xml;charset=utf-8',
    });
    const url = URL.createObjectURL(blob);
    doDownload(url, 'QRCode.svg');
};
const CustomQRCode = () => {
    const [renderType, setRenderType] = React.useState('canvas');
    return (
        <Space id="myqrcode" direction="vertical">
            <Segmented options={['canvas', 'svg']} value={renderType} onChange={setRenderType} />
            <div>
                <QRCode
                    type={renderType}
                    value="https://www.youthfp.cm"
                    bgColor="#fff"
                    style={{
                        marginBottom: 16,
                    }}
                />
                <Button
                    type="primary"
                    onClick={renderType === 'canvas' ? downloadCanvasQRCode : downloadSvgQRCode}
                >
                    Telecharge
                </Button>
            </div>
        </Space>
    );
};
export default CustomQRCode;