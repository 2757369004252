import {Button, Modal, Tooltip, message} from "antd";
import React, {useState} from "react";
import { useSelector } from "react-redux";
import axiosInstance from "../../configurations/axiosInstance/AxiosInstance";
import {toastClick} from "../toast/CustomToast";
import {LockFilled} from "@ant-design/icons";

export function ResetPWD({path, status,id}) {
    const {accessToken} = useSelector(state => state.authUser);
    const[loading, setLoading] = useState(false);

    const onChange =async ()=>{
        setLoading(true);
        await axiosInstance(accessToken).put(
            path+id
        ).then((result)=> {
            toastClick("Reinitialisation du mot de passe effectue",'success')
            window.location.reload(true);
            setLoading(false);
        }).catch((result)=>{
            message.error(result.response.data.message)
            setLoading(false);
        });
    }
    return (
        <>
            <Tooltip title={"Reinitialiser le mot de passe"}>
                <Button disabled={loading} loading={loading} type="default" shape="circle"  onClick={onChange} >
                    <i aria-hidden="true"><LockFilled /></i>
                </Button>
            </Tooltip>
        </>
    )
}