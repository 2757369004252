const BASE='/admin/';
export const DASHBOARDSHORT='dashboard';
export const DASHBOARD=BASE+DASHBOARDSHORT;
export const PROFILE=BASE+'profile';

export const QRCODE=BASE+'qrcode';
export const PARAMETRE=BASE+'parametre';
export const USER=BASE+'user';
export const DENONCIATION=BASE+'denonciation';
export const GROUPE=BASE+'group';
export const ARTICLE=BASE+'article';
export const TEMOIGNAGE=BASE+'temoignage';
export const CP=BASE+'centrepartenaire';
export const MISSION=BASE+'mission';
export const VALEUR=BASE+'valeur';
export const QUIZZ=BASE+'quizz';
export const ELEARNING=BASE+'formation';
export const THEME=BASE+'theme';
export const APROPOS=BASE+'apropos';
export const UNAUTHORIZE=BASE+'unauthorize';