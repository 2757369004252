import React from 'react';
import './background.css';
export default function Background(){
    return (
        <div className="background">
            {Array.from({ length: 50 }).map((_, index) => (
                <span key={index}></span>
            ))}
        </div>
    );
}