import React, {useEffect, useState} from "react";
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";
import {Breadcrumb, Button, Space, Tag, Tooltip} from "antd";
import TableTemplate from "../../../components/table/index";
import {useNavigate} from "react-router-dom";
import {Active} from "../../../components/active/index";
import AddValeur from "../add";
import {useRole, useTitle} from "../../../components/hook/index";
import {useSelector} from "react-redux";
import {EditFilled, InfoCircleFilled} from "@ant-design/icons";
import BreadCom from "../../../components/breadCom/BreadCom";

export default function ListValeur(){
    const {accessToken} = useSelector(state => state.authUser);
    const [loading, setLoading] = useState(true);
    const nav = useNavigate();
    const [valeurs, setValeurs] = useState([]);
    useRole(['ROOT','ADMIN','SUPERADMIN']);
    useTitle('Liste des Valeurs');
    const navigate= (link)=>{
        nav(link);
    }
    const fetchValeurs = () => {
        setLoading(true);
        axiosInstance(accessToken).get('api/valeur').then(
            (response)=>{
                setValeurs(response.data);
                setLoading(false);
            }
        ).catch( (error)=>{
            setLoading(false);
        });
    }
    useEffect(() => {
        fetchValeurs();
    }, []);
    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Titre',
            key: 'title',
            render:  (_, record) => (
                <>
          <span>
            {record.title}
          </span>
                </>
            ),
        },
        {
            title: 'Status',
            key: 'status',
            render:  (_, record) => {
                let result = null;
                if(record.status){
                    result = (
                        <Tag color="green" key={record.id} >
                            Activer
                        </Tag>
                    )
                }else if(!record.status){
                    result = (
                        <Tag color="volcano"  key={record.id}>
                            Desactiver
                        </Tag>
                    )
                }else {
                    result = (
                        <Tag color="volcano"  key={record.id}>
                            Unknow
                        </Tag>
                    )
                }
                return (
                    <>
                        {result}
                    </>
                )
            } ,
        },
        {
            title: 'Action',
            key: 'action',
            render:  (_, record) => {
                return  (
                    <>
                        <Space>
                            <Tooltip title={"Detail sur la valeur"}>
                                <Button type="primary" shape="circle"  onClick={() => navigate('/admin/valeur/'+record.id)} >
                                    <i aria-hidden="true"><InfoCircleFilled /></i>
                                </Button>
                            </Tooltip>
                            <Tooltip title={"Mise a jour d'une valeur"}>
                                <Button type="primary" shape="circle"  onClick={() => navigate('/admin/valeur/update/'+record.id)} >
                                    <i aria-hidden="true"><EditFilled /></i>
                                </Button>
                            </Tooltip>
                            <Active status={record.status} path={"api/valeur/active/"} id={record.id}/>
                        </Space>
                    </>
                )
            }
        },
    ];


    return(
        <>
            <BreadCom name={"Liste des valeurs"}/>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <section className="col-lg-12">
                            <div className="card card-primary" style={{background: '#ffffff00'}}>
                                <div className="card-header">
                                    <h3 className="card-title">
                                        <i className="fas fa-chart-pie mr-1"></i>
                                        Liste des Valeurs
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <div className="tab-content p-0">
                                        <TableTemplate columns={columns} loading={loading} addBTN={<AddValeur />} datas={valeurs} />                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                </div>
            </section>
        </>
    );

}