import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useRole, useTitle} from "../../../components/hook";
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";
import {toastClick} from "../../../components/toast/CustomToast";
import BreadCom from "../../../components/breadCom/BreadCom";
import {Empty, Spin} from "antd";

export default function ViewParametres(){
    const params = useParams();
    const [parametre, setParametre] = useState(null);
    const [loading, setLoading] = useState(true);
    const {accessToken} = useSelector(state => state.authUser);
    useRole(['ROOT','ADMIN','SUPERADMIN']);
    useTitle('View Parametre');
    const getTemoignageById = () => {
        axiosInstance(accessToken).get('api/parametres/find-by-id/'+parseInt(params.id))
            .then(
                (response)=>{
                    setParametre(response.data);
                    setLoading(false);
                }
            ).catch(
            (error)=>{
                toastClick(error.response.data.message,'error');
                setLoading(false);
            }
        )
    }

    useEffect(
        ()=>{
            setLoading(true)
            getTemoignageById();
        },[]
    );
    const returnValue = parametre ==null ? <>
        <Spin spinning={loading}>
            <Empty />
        </Spin>
    </>  : <>
        <Spin  spinning={loading}>
            <label>
                <strong>Titre :</strong> <span style={{fontSize: '24px', padding: "5px", marginTop: '5px'}}>{parametre.titre}</span>
            </label>
            <hr/>
            <label>
                <strong>Valeur :</strong><span style={{fontSize: '24px', padding: "5px", marginTop: '5px'}}>{parametre.valeur == null ? "none" : parametre.valeur}</span>
            </label>
            <hr/>
            <label>
                <strong>Taille :</strong><span style={{fontSize: '24px', padding: "5px", marginTop: '5px'}}>{parametre.taille == null ? "none" : parametre.taille}</span>
            </label>
            <hr/>
            <label>
                <strong>Type :</strong><span style={{fontSize: '24px', padding: "5px", marginTop: '5px'}}>{parametre.paramTypeEnum == null ? "none" : parametre.paramTypeEnum}</span>
            </label>
        </Spin>
    </>
    return(
        <>
            <BreadCom name={"Parametre info"}/>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <section className="col-lg-12">
                            <div className="card card-primary">
                                <div className="card-header">
                                    <h3 className="card-title">
                                        <i className="fas fa-chart-pie mr-1"></i>
                                        Detail
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <div className="tab-content p-0">
                                        {returnValue}
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                </div>
            </section>
        </>
    );

}