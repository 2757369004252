import {Button, Tooltip, message, Spin, Form, Input, Select, Space, Modal, Tag} from "antd";
import React, {useEffect, useState} from "react";
import { useSelector } from "react-redux";
import axiosInstance from "../../configurations/axiosInstance/AxiosInstance";
import {PlusCircleOutlined, WindowsOutlined} from "@ant-design/icons";

export function SetQuizzToFormationQuestionResponse({formation}) {
    const {accessToken} = useSelector(state => state.authUser);
    const[loading, setLoading] = useState(false);
    const[questionResponse, setQuestionResponse] = useState([{question:null,response:null}]);
    const[question, setQuestion] = useState(null);
    const[responseView, setResponseView] = useState(false);
    const[responses, setResponses] = useState([]);
    const[response, setResponse] = useState(null);
    const [modalAddOpen, setModalAddOpen] = useState(false);
    const [form] = Form.useForm();
    const openModal =()=>{
        form.resetFields();
        setLoading(false)
        setModalAddOpen(true)
        setQuestionResponse([]);
        setResponses([]);
        setQuestion(null);
        setResponse(null);
        setResponseView(false);
        sessionStorage.setItem("quizz", JSON.stringify(formation.quizz.id));
        sessionStorage.setItem("formation", JSON.stringify(formation.id));
    }
    const compare = (a,b)=>{
        if(a.id<b.id)
            return -1;
        if(a.id>b.id)
            return 1;
        return 0;
    } ;
    const handleQuestionResponse = (event)=>{
        let questions=JSON.parse(event);
        setQuestion(questions)
        setResponses(questions.modelResponses.sort(compare));
        setResponseView(true);
        setResponse(null);
        form.resetFields(["reponse"])
    }
    const handleResponse = (event)=>{
        let respons=JSON.parse(event);
        setResponse(respons)
    };
    const addResponseToQuestion = ()=>{
        let data = questionResponse;
        data.push({question:question,response:response});
        setQuestionResponse(data);
        setQuestion(null);
        setResponse(null);
        setResponseView(false);
        form.resetFields(["reponse"]);
        form.resetFields(["question"]);
    }

    const onSubmit = (values) => {
        setLoading(true);
        let body=[];
        questionResponse.forEach(value => {
            body.push({
                question:value.question.id,
                response:value.response.id
            });
        })
        let data={
            idSondage:sessionStorage.getItem("quizz"),
            idFormation:sessionStorage.getItem("formation"),
            body:body
        }
        axiosInstance(accessToken).post(
            "test/api/sondage/question_response",data
        ).then(
            (result)=> {
                message.success('Reponse configurer avec success');
                form.resetFields();
                window.location.reload();
                setModalAddOpen(false);
                setLoading(false);
            }).catch((result)=> {
            message.error(result.response.data.message);
            setLoading(false);
        });
    }
    return (
        <>
            <Tooltip title={"Selection des bonnes reponses"}>
                <Button disabled={loading} loading={loading} type="default" shape="circle"  onClick={()=>{openModal()}} >
                    <i aria-hidden="true"><WindowsOutlined /></i>
                </Button>
            </Tooltip>
            <Modal
                title={"Selection des bonnes reponses aux questions du quizz de la formation "+formation.libelle}
                style={{
                    top: 20,
                }}
                width={1000}
                open={modalAddOpen}
                maskClosable={false}
                closable={false}
                footer={[]}
            >
                <Spin spinning={loading}>
                    <Form onFinish={onSubmit} form={form}>

                        {
                            questionResponse.length !== formation.quizz.questions.length && <>
                                {modalAddOpen && <Form.Item
                                    label="Question"
                                    name="question"
                                    rules={
                                        [
                                            {required: true, message: 'Veuillez selectionner une question'}
                                        ]}
                                    hasFeedback>
                                    <Select placeholder="Choisir la question" onChange={handleQuestionResponse}>
                                        {
                                            questionResponse.length === 0 ? <>
                                                {
                                                    formation.quizz.questions.sort(compare).map((value, index) => {
                                                        return <Select.Option key={"Q_" + value.id} value={JSON.stringify(value)}>{value.libelle}</Select.Option>;
                                                    })
                                                }
                                            </> : <>
                                                {
                                                    formation.quizz.questions.sort(compare).map((value, index) => {
                                                        let data = [];
                                                        questionResponse.forEach((value1, index1) => {
                                                            if(value.id != value1.question.id)
                                                                data.push(<Select.Option key={"Q_"+value.id} value={JSON.stringify(value)}>{value.libelle}</Select.Option>);
                                                        });
                                                        return data;
                                                    })
                                                }
                                            </>

                                        }
                                    </Select>
                                </Form.Item>}
                                {responseView && <Form.Item
                                    label="Reponse"
                                    name="reponse"
                                    rules={
                                        [
                                            { required: true, message: 'Veuillez selectionner une reponse' }
                                        ]}
                                    hasFeedback>
                                    <Select placeholder="Choisir la reonse a la question" onChange={handleResponse}>
                                        {
                                            responses.map((value, index)=>{
                                                return <Select.Option key={"R_"+value.id} value={JSON.stringify(value)}>{value.value}</Select.Option>;
                                            })
                                        }
                                    </Select>
                                </Form.Item>}
                            </>
                        }

                        <Form.Item>
                            {
                                questionResponse.length !== formation.quizz.questions.length && <Space style={{float:'left'}}>
                                    <Button type='primary' onClick={()=>{addResponseToQuestion()}} disabled={response===null}>
                                        Ajouter
                                    </Button>
                                </Space>
                            }
                            <br />
                            <hr />
                            <Space style={{float:'right'}}>
                                <Button type='primary' htmlType='submit' disabled={questionResponse.length !== formation.quizz.questions.length}>
                                    Submit
                                </Button>
                                <Button danger  disabled={loading}  onClick={() => setModalAddOpen(false)} >
                                    <i className="fa fa-times" aria-hidden="true"></i> cancel
                                </Button>

                            </Space>
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
        </>
    )
}