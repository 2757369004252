import {Button, Tooltip, message} from "antd";
import React, {useState} from "react";
import { useSelector } from "react-redux";
import axiosInstance from "../../configurations/axiosInstance/AxiosInstance";
import {EyeInvisibleOutlined, EyeOutlined} from "@ant-design/icons";
import {toastClick} from "../toast/CustomToast";

export function Active({path, status,id}) {
    const {accessToken, droits} = useSelector(state => state.authUser);
    const[loading, setLoading] = useState(false);
    const returnValue = status ? (
        <i aria-hidden="true"><EyeOutlined /></i>
    ) : (
        <i aria-hidden="true"><EyeInvisibleOutlined /></i>
    );

    const onChange =async ()=>{
        setLoading(true);
        await axiosInstance(accessToken).put(
            path+id
        ).then((result)=> {
            toastClick("Mise a jour effectuee","success")
            window.location.reload(true);
            setLoading(false);
        }).catch((result)=>{
            toastClick(result.response.data.message,'error')
            setLoading(false);
        });
    }
    return (
        <>
            {
                droits.includes("SUPERADMIN") && <Tooltip title={"Activer ou Desactiver"}>
                    <Button disabled={loading} loading={loading} type="default" shape="circle"  onClick={onChange} >
                        {returnValue}
                    </Button>
                </Tooltip>
            }

        </>
    )
}