import { Select } from 'antd';
import React from 'react';

const FontSizePicker = ({ onChange }) => {
  const fontSizeOptions = [8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23, 24, 28];

  const handleFontSizeChange = (fontSize) => {
    if (onChange) {
      onChange(parseInt(fontSize));
    }
  };

  return (
    <div>
      <Select placeholder="Font Size" onChange={(e) => handleFontSizeChange(e)} onClick={()=>{}}>
        {fontSizeOptions.map((size) => (
          <Select.Option value={size} key={size} >
            {size}px
          </Select.Option >
        ))}
      </Select>
    </div>
  );
};

export default FontSizePicker;
