export default function Loader({view=false}){

    return (
        <div style={
            {display: view ? 'flex':'none'}
        }>
            <div className="preloader flex-column justify-content-center align-items-center"  id="preloader">
                <img className="animation__shake" src="/dist/img/AdminLTELogo.png" alt="logo" height="60" width="60"/>
            </div>
        </div>
    );
}