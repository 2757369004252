import {Button, Tooltip, message, Spin, Form, Input, Select, Space, Modal} from "antd";
import React, {useState} from "react";
import { useSelector } from "react-redux";
import axiosInstance from "../../configurations/axiosInstance/AxiosInstance";
import {PlusCircleOutlined} from "@ant-design/icons";

export function AddQuizzToFormation({formation, quizz}) {
    const {accessToken} = useSelector(state => state.authUser);
    const[loading, setLoading] = useState(false);
    const [modalAddOpen, setModalAddOpen] = useState(false);
    const [form] = Form.useForm();
    const openModal =()=>{
        form.resetFields();
        setLoading(false)
        setModalAddOpen(true)
    }

    const onSubmit = (values) => {
        setLoading(true);
        axiosInstance(accessToken).put(
            "test/api/formations/add_quizz/"+formation.id+"/"+values.quizz
        ).then(
            (result)=> {
                message.success('Quizz ajouter a la formation');
                form.resetFields();
                setModalAddOpen(false);
                setLoading(false);
                setTimeout(
                    ()=>{
                        window.location.reload();
                    },2000
                )
            }).catch((result)=> {
            message.error(result.response.data.message);
            setLoading(false);
        });
    }
    return (
        <>
            <Tooltip title={"Ajouter un quizz"}>
                <Button disabled={loading} loading={loading} type="default" shape="circle"  onClick={()=>{openModal()}} >
                    <i aria-hidden="true"><PlusCircleOutlined /></i>
                </Button>
            </Tooltip>
            <Modal
                title={"Ajouter un quizz a la formation "+formation.libelle}
                style={{
                    top: 20,
                }}
                width={1000}
                open={modalAddOpen}
                maskClosable={false}
                closable={false}
                footer={[]}
            >
                <Spin spinning={loading}>
                    <Form onFinish={onSubmit} form={form}>

                        <Form.Item
                            label="Quizz"
                            name="quizz"
                            rules={
                                [
                                    { required: true, message: 'Veuillez selectionner un quizz' }
                                ]}
                            hasFeedback>
                            <Select placeholder="Choisir le quizz">
                                {
                                    quizz.map((value, index)=>{
                                        return <Select.Option key={value.id} value={value.id}>{value.name}</Select.Option>;
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item>
                            <Space style={{float:'right'}}>
                                <Button type='primary' htmlType='submit' disabled={loading}>
                                    Submit
                                </Button>
                                <Button danger  disabled={loading}  onClick={() => setModalAddOpen(false)} >
                                    <i className="fa fa-times" aria-hidden="true"></i> cancel
                                </Button>

                            </Space>
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
        </>
    )
}