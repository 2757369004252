import React, {useEffect, useState} from "react";
import {useRole, useTitle} from "../../components/hook";
import BreadCom from "../../components/breadCom/BreadCom";
import CustomQRCode from "../../components/customQRCode/CustomQRCode";


export default function ViewQRCode() {
    useTitle('QRCode');

    return(
        <>
            <BreadCom name={"QRCode"}/>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <section className="col-lg-12">
                            <div className="card card-primary" style={{background: '#ffffff00'}}>
                                <div className="card-header">
                                    <h3 className="card-title">
                                        <i className="fas fa-chart-pie mr-1"></i>
                                        Generer un QRCode
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <div className="tab-content p-0">
                                        <CustomQRCode />
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                </div>
            </section>
        </>
    );
}