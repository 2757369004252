import {Link, Outlet, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Breadcrumb, Button, Col, Empty, message, Row, Spin} from "antd";
import {useRole, useTitle} from "../../../components/hook";
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";
import BreadCom from "../../../components/breadCom/BreadCom";
import {useSelector} from "react-redux";

export default function ViewTemoignage() {
    const {accessToken} = useSelector(state => state.authUser);
    const params = useParams();
    const nav = useNavigate();
    const [temoignage, setTemoignage] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigation = (link)=>{
        setLoading(true);
        nav(link);
        setLoading(false);
    }
    useRole(['ROOT','ADMIN','SUPERADMIN']);
    useTitle('View Temoignage');
    const getTemoignageById = () => {
        axiosInstance(accessToken).get('api/temoignage/find-by-id/'+parseInt(params.id))
            .then(
                (response)=>{
                    setTemoignage(response.data);
                    setLoading(false);
                }
            ).catch(
            (error)=>{
                message.error(error.response.data.message);
                setLoading(false);
            }
        )
    }

    useEffect(
        ()=>{
            setLoading(true)
            getTemoignageById();
        },[]
    )
    const returnValue = temoignage ==null ? <>
        <Spin spinning={loading}>
            <Empty />
        </Spin>
    </>  : <>
        <Spin  spinning={loading}>
            <div>
                <Row>
                    <Col  >
                        <Row>
                            <Col >
                                <strong>Titre :</strong> <span style={{fontSize: '24px', padding: "5px", marginTop: '5px'}}>{temoignage.title}</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col >
                                <strong>Auteur :</strong><span style={{fontSize: '24px', padding: "5px", marginTop: '5px'}}>{temoignage.author == null ? "Anonymous" : temoignage.author}</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col >
                                <strong>Date :</strong><span style={{fontSize: '24px', padding: "5px", marginTop: '5px'}}>{temoignage.date == null ? "Anonymous" : temoignage.date}</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col >
                                <strong>Contenu :</strong><span style={{fontSize: '24px', padding: "5px", marginTop: '5px'}}>{temoignage.contenu == null ? "Anonymous" : temoignage.contenu}</span>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </Spin>
    </>

    return(
        <>
            <BreadCom name={"Temoignage info"}/>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <section className="col-lg-12">
                            <div className="card card-primary" style={{background: '#ffffff00'}}>
                                <div className="card-header">
                                    <h3 className="card-title">
                                        <i className="fas fa-chart-pie mr-1"></i>
                                        Detail
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <div className="tab-content p-0">
                                        {returnValue}
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                </div>
            </section>
        </>
    );
}