import {useRole, useTitle} from "../../../components/hook";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Button, Space, Tag, Tooltip} from "antd";
import {EditFilled, InfoCircleFilled} from "@ant-design/icons";
import {Active} from "../../../components/active";
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";
import BreadCom from "../../../components/breadCom/BreadCom";
import TableTemplate from "../../../components/table";
import AddArticle from "../../article/add";
import AddAPropos from "../add/AddAPropos";

export default function ListAPropos(){
    const {droits} = useSelector(state => state.authUser);
    useRole(['ROOT','ADMIN','SUPERADMIN']);
    useTitle('APropos');
    const nav = useNavigate();
    const [aPropos, setAPropos] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate= (link)=>{
        nav(link);
    }
    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'key',
        },
        {
            title: 'A Propos',
            key: 'about',
            render:  (_, record) => {

                return (
                    <>
                        <Space>
                            <p>A Propos</p>
                        </Space>
                    </>
                );
            },
        },
        {
            title: 'Action',
            key: 'action',
            render:  (_, record) => {

                return (
                    <>
                        <Space>
                            <Tooltip title={"voir"}>
                                <Button type="primary" shape="circle"  onClick={() => navigate('/admin/apropos/'+record.id)} >
                                    <i aria-hidden="true"><InfoCircleFilled /></i>
                                </Button>
                            </Tooltip>
                            <Tooltip title={"Mise a jour "}>
                                <Button type="primary" shape="circle"  onClick={() => navigate('/admin/apropos/update/'+record.id)} >
                                    <i aria-hidden="true"><EditFilled /></i>
                                </Button>
                            </Tooltip>
                        </Space>
                    </>
                );
            },
        },
    ];
    const fetchAPropos = ()=>{
        setLoading(true);
        axiosInstance().get(
            "api/apropos/list"
        ).then((result)=> {
            if(result.data != null){
                let data = [];
                data.push(result.data);
                setAPropos(data);
            }
            setLoading(false);
        }).catch((result)=> {
            setLoading(false);
        });
    }
    useEffect(()=>{
        fetchAPropos()
    },[]);

    return(
        <>
            <BreadCom name={"A Propos"}/>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <section className="col-lg-12">
                            <div className="card card-primary" style={{background: '#ffffff00'}}>
                                <div className="card-header">
                                    <h3 className="card-title">
                                        <i className="fas fa-chart-pie mr-1"></i>
                                        A Propos
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <div className="tab-content p-0">
                                        <TableTemplate
                                            columns={columns}
                                            loading={loading}
                                            addBTN={aPropos.length==0 ? null : <AddAPropos loading={"right"} action={"Ajouter"} />}
                                            datas={aPropos} />
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                </div>
            </section>
        </>
    );
}