import {Link, useNavigate, useParams} from "react-router-dom";
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";
import React, {useEffect, useState} from "react";
import {Breadcrumb, Button, Col, Empty, message, Row, Space, Spin, Tag, Tooltip} from "antd";
import TableTemplate from "../../../components/table";
import {useRole, useTitle} from "../../../components/hook";
import {useSelector} from "react-redux";
import AddService from "../add/indexServiceAdd";
import BreadCom from "../../../components/breadCom/BreadCom";

export default function ViewCentrePartenaire() {
    const {accessToken} = useSelector(state => state.authUser);
    const params = useParams();
    const nav = useNavigate();
    const [denonciation, setDenonciation] = useState(null);
    const [service, setService] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingS, setLoadingS] = useState(true);
    useRole(['ROOT','ADMIN','SUPERADMIN']);
    useTitle('View Centre Partenaire');
    const navigation = (link)=>{
        setLoading(true);
        nav(link);
        setLoading(false);
    }
    const getDenonciationById = () => {
        axiosInstance(accessToken).get('api/centrepartenaire/getById/'+parseInt(params.id))
            .then(
                (response)=>{
                    setDenonciation(response.data);
                    setLoading(false);
                    setLoadingS(true);
                    axiosInstance(accessToken).get('service/list-by-cp-id/'+parseInt(response.data.id))
                        .then(
                            (response1)=>{
                                setService(response1.data);
                                setLoadingS(false);
                            }
                        ).catch(
                        (error)=>{
                            setLoadingS(false);
                        }
                    );
                }
            ).catch(
            (error)=>{
                message.error("Error");
                setLoading(false);
            }
        );
    }

    useEffect(
        ()=>{
            setLoading(true)
            getDenonciationById();
        },[]
    )

    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Libelle',
            key: 'libelle',
            dataIndex: 'libelle',
        },
        {
            title: 'Description',
            key: 'description',
            dataIndex: 'description',
        },
    ];
    const returnValue = denonciation ==null ? <>
        <Spin spinning={loading}>
            <Empty />
        </Spin>
    </>  : <>
        <Spin  spinning={loading}>
            <div style={{background: '#ffffff00'}}>
                <Row>
                    <Col  >
                        <Row>
                            <Col >
                                <strong>Nom :</strong>
                                <span style={{fontSize: '24px', padding: "5px", marginTop: '5px'}}>{denonciation.nom}</span>
                            </Col>
                        </Row>
                        <Row style={{marginTop: '5px'}}>
                            <Col >
                                <strong>Libelle :</strong>
                                <span style={{fontSize: '24px', padding: "5px", marginTop: '5px'}}>{denonciation.libelle}</span>
                            </Col>
                        </Row>
                        <Row style={{marginTop: '5px'}}>
                            <Col >
                                <strong>N° Tel :</strong>
                                <span style={{fontSize: '24px', padding: "5px", marginTop: '5px'}}>{denonciation.telephone}</span>
                            </Col>
                        </Row>
                        <Row style={{marginTop: '5px'}}>
                            <Col >
                                <strong>Adresse :</strong>
                                <span style={{fontSize: '24px', padding: "5px", marginTop: '5px'}}>{denonciation.adresse}</span>
                            </Col>
                        </Row>
                        <Row style={{marginTop: '5px'}}>
                            <Col >
                                <strong>Longitude :</strong>
                                <span style={{fontSize: '24px', padding: "5px", marginTop: '5px'}}>{denonciation.longitude}</span>
                            </Col>
                        </Row>
                        <Row style={{marginTop: '5px'}}>
                            <Col >
                                <strong>Latitude :</strong>
                                <span style={{fontSize: '24px', padding: "5px", marginTop: '5px'}}>{denonciation.latittude}</span>
                            </Col>
                        </Row>
                        <Row style={{marginTop: '5px'}}>
                            <Col >
                                <strong>Administrateur :</strong><span style={{fontSize: '24px', padding: "5px", marginTop: '5px'}}>{denonciation.createur == null ? "Anonymous" : denonciation.createur.nom+' '+denonciation.createur.prenom}</span>
                            </Col>
                        </Row>
                        <Row style={{marginTop: '5px'}}>
                            <Col >
                                <strong>Status :</strong><span style={{fontSize: '24px', padding: "5px", marginTop: '5px'}}>{denonciation.status == null ? "Unknow" : denonciation.status}</span>
                            </Col>
                        </Row>
                        <Row style={{marginTop: '5px'}}>
                            <Col >
                                <strong>Services :</strong>
                                <br/>

                            </Col>
                        </Row>
                    </Col>
                </Row>
                {
                    service.length == 0 ? <Spin spinning={loadingS}>{loadingS ? <Empty /> : <div style={
                        {
                            display: "flex",
                            justifyContent: 'center'
                        }
                    }><AddService centreP={denonciation} /></div> }</Spin> : <TableTemplate columns={columns} loading={loadingS} addBTN={<AddService centreP={denonciation} />} datas={service} />
                }
            </div>
        </Spin>
    </>

    return(
        <>
            <BreadCom name={"Centre Partenaire info"}/>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <section className="col-lg-12">
                            <div className="card card-primary" style={{background: '#ffffff00'}}>
                                <div className="card-header">
                                    <h3 className="card-title">
                                        <i className="fas fa-chart-pie mr-1"></i>
                                        Detail
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <div className="tab-content p-0">
                                        {returnValue}
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                </div>
            </section>
        </>
    );
}