import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {useRole, useTitle} from "../../../components/hook";
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";
import {toastClick} from "../../../components/toast/CustomToast";
import {Button, Col, Empty, Row, Spin, Tag} from "antd";
import BreadCom from "../../../components/breadCom/BreadCom";
import {useSelector} from "react-redux";



export default function ViewUser() {
    const {accessToken} = useSelector(state => state.authUser);
    const params = useParams();
    const nav = useNavigate();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [ddn, setDdn] = useState('');
    useRole(['ROOT','ADMIN','SUPERADMIN']);
    useTitle('View User');
    const navigation = (link)=>{
        setLoading(true);
        nav(link);
    }
    const getUserById = () => {
        axiosInstance(accessToken).get('user/find-by-id/'+parseInt(params.id))
            .then(
                (response)=>{
                    setUser(response.data);
                    if(response.data.ddn != null){
                        let date = new Date(response.data.ddn);
                        setDdn((date.getDay()+1)+'-'+(date.getMonth()+1)+'-'+date.getFullYear());
                    }
                    setLoading(false);
                }
            ).catch(
            (error)=>{
                setLoading(false);
                toastClick(error.response.data.message,'error');
            }
        )
    }

    useEffect(
        ()=>{
            setLoading(true)
            getUserById();
        },[]
    )
    const returnValue = user ==null ? <>
        <Spin spinning={loading}>
            <Empty />
        </Spin>
    </>  : <>
        <Spin  spinning={loading}>
            <div>

            </div>
            <div>
                <Row style={{float: 'right', marginBottom: '15px'}}>
                    <Col>
                        <Row>
                            <Col style={{marginTop: '5px'}}>
                                <Tag title={user.status} color={user.status === 'INACTIVE' ? "volcano" : user.status !== 'ACTIVE' ? "volcano" : "green"}  key={user.id} >
                                    {user.status === 'INACTIVE' ? "inactive" : user.status !== 'ACTIVE' ? "unknow" : "active"}
                                </Tag>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{marginTop: '5px'}}>
                                <Button type="primary" onClick={()=>{navigation('/admin/user/update/'+user.id)}}>Edit</Button>
                            </Col>
                        </Row>


                    </Col>
                </Row>
                <Row>

                    <Col >
                        <img src={user.photo == null ? '/dist/img/user2-160x160.jpg' : user.photo} width={'200px'} alt={""}/>
                    </Col>
                    <Col  >
                        <label>
                            <strong>Nom :</strong> <span style={{fontSize: '24px', padding: "5px", marginTop: '5px'}}>{user.nom}</span>
                        </label>
                        <hr/>
                        <label>
                            <strong>Prenom :</strong> <span style={{fontSize: '24px', padding: "5px", marginTop: '5px'}}>{user.prenom}</span>
                        </label>
                        <hr/>
                        <label>
                            <strong>Date de Naissance :</strong><span style={{fontSize: '24px', padding: "5px", marginTop: '5px'}}>{user.ddn == null ? "null" : ddn}</span>
                        </label>
                        <hr/>
                        <label>
                            <strong>Lieu :</strong><span style={{fontSize: '24px', padding: "5px", marginTop: '5px'}}>{user.lieu == null ? "null" : user.lieu}</span>
                        </label>
                        <hr/>
                        <label>
                            <strong>Sexe :</strong><span style={{fontSize: '24px', padding: "5px", marginTop: '5px'}}>{user.sexe == null ? "" : user.sexe}</span>
                        </label>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <label>
                            <strong>Phone :</strong> <span style={{fontSize: '24px', padding: "5px", marginTop: '10px'}}>{user.numero_telephone == null ? "null" : user.numero_telephone}</span>
                        </label>
                        <hr/>
                        <label>
                            <strong>Email :</strong> <span style={{fontSize: '24px', padding: "5px", marginTop: '10px'}}>{user.email == null ? "null" : user.email}</span>
                        </label>
                        <hr/>
                        <label>
                            <strong>Groupe :</strong><span style={{fontSize: '24px', padding: "5px", marginTop: '10px'}}>{user.groupe == null ? "null" : '('+user.groupe.name+') '+ user.groupe.libelle}</span>
                        </label>
                    </Col>
                </Row>
            </div>
        </Spin>
    </>;

    return(
        <>
            <BreadCom name={"Utilisateur info"}/>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <section className="col-lg-12">
                            <div className="card card-primary" style={{background: '#ffffff00'}}>
                                <div className="card-header">
                                    <h3 className="card-title">
                                        <i className="fas fa-chart-pie mr-1"></i>
                                        Detail
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <div className="tab-content p-0">
                                        {returnValue}
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                </div>
            </section>
        </>
    );

}