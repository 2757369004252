import React, {useEffect, useState} from 'react';
import {Button, Form, Input, Modal, Select, Space, message, Spin} from 'antd';
import { useSelector } from "react-redux";
import axiosInstance from "../../../configurations/axiosInstance/AxiosInstance";
import {useRole} from "../../../components/hook";

export default function AddCP() {
    useRole(['ROOT','ADMIN','SUPERADMIN']);
    const {accessToken} = useSelector(state => state.authUser);
    const [modalAddOpen, setModalAddOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState(null);
    const [form] = Form.useForm();
    const [users, setUsers] = useState([]);
    const openModal =()=>{
        form.resetFields();
        setLoading(false);
        setModalAddOpen(true);
        setFile(null);
    }
    const fetchGroupes = () => {
            axiosInstance(accessToken).get('user/admin/get/cp')
                .then(
                    response=>{
                        setUsers(response.data);
                    }
                ).catch(
                    error=>{
                        message.error("User not found")
                    }
            );

    };
    useEffect(() => {
        fetchGroupes();
    }, []);
    const onSubmit = (values) => {
        if(file === null){
            message.error('Choisir le logo du centre partenaire');
        }else {
            setLoading(true);
            let createur = null;
            for (let g in users)
                if(users[g].id === values.createur)
                    createur = users[g];
            const dat = {
                category:parseInt(values.category),
                createur:createur,
                description:values.description,
                latittude:values.latittude,
                libelle:values.libelle,
                location:values.location,
                longitude:values.longitude,
                morgue:values.morgue,
                nom:values.nom,
                nomCommune:values.nomCommune,
                nombreBat:parseInt(values.nombreBat),
                nombreLit:parseInt(values.nombreLit),
                photo:null,
                region:values.region,
                status:"ACTIVE",
                superficie:parseFloat(values.superficie),
                telephone:values.telephone
            };
            const formData = new FormData();
            formData.append('file', file);
            formData.append('jsonData', JSON.stringify(dat));
            let config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
            axiosInstance(accessToken).post(
                "api/centrepartenaire/save",
                formData,config
            ).then(
                (result)=> {
                    message.success('Centre partenaire '+dat.libelle+' '+dat.nom+' Create');
                    setTimeout(
                        ()=>{
                            form.resetFields();
                            setLoading(false);
                            window.location.reload();
                            setModalAddOpen(false);
                        },2000
                    )
                }).catch((error)=> {
                setLoading(false);
                message.error(error.response.data.error);
            });
        }

    };
    const getFiles = (event)=>{
        if(event.target.files.length>0) {
            setFile(event.target.files[0]);
        }
    }

    return(
        <>
            <Button style={{float: "right"}}  onClick={openModal} >
                <i className="fa fa-plus" aria-hidden="true"></i>Ajouter
            </Button>
            <Modal
                title="Creation d'un Centre Partenaire"
                style={{
                    top: 20,
                }}
                width={1000}
                open={modalAddOpen}
                maskClosable={false}
                closable={false}
                footer={[]}
            >
                <Spin spinning={loading}>
                    <Form onFinish={onSubmit} form={form}>
                        <Form.Item
                            label="Nom"
                            name="nom"
                            rules={
                                [
                                    { required: true, message: 'Veuillez entrer le nom' },
                                    {type:"string", message:"Entrer un nom valide !"}
                                ]}
                            hasFeedback>
                            <Input placeholder="entrer le nom"/>
                        </Form.Item>
                        <Form.Item
                            label="Abbreviation"
                            name="libelle"
                            rules={
                                [
                                    { required: true, message: "Veuillez entrer l'abbreviation" },
                                    {type:"string", message:"Veuillez entrer l'abbreviation"}
                                ]}
                            hasFeedback>
                            <Input placeholder="Veuillez entrer l'\abbreviation"/>
                        </Form.Item>

                        <Form.Item
                            label="Latitude"
                            name="latittude"
                            rules={
                                [
                                    { required: true, message: "Veuillez entrer la Latitude" },
                                    { pattern: /^-?([1-8]?\d(\.\d+)?|90(\.0+)?)$/, message: "Veuillez entrer une latitude valide" }
                                ]}
                            hasFeedback>
                            <Input placeholder="Veuillez entrer la Latitude"/>
                        </Form.Item>
                        <Form.Item
                            label="Longitude"
                            name="longitude"
                            rules={
                                [
                                    { required: true, message: "Veuillez entrer la Longitude" },
                                    { pattern: /^-?([1-8]?\d(\.\d+)?|90(\.0+)?)$/, message: "Veuillez entrer une longitude valide" }
                                ]}
                            hasFeedback>
                            <Input placeholder="Veuillez entrer la Longitude"/>
                        </Form.Item>
                        <Form.Item
                            label="Administrateur"
                            name="createur"
                            rules={
                                [
                                    { required: true, message: "Selectionner l'administrateur" },

                                ]}
                            hasFeedback>
                            <Select placeholder="Choisir l'administrateur">
                                {
                                    users.map((user)=>{
                                        return (<Select.Option
                                            key={user.id}
                                            value={user.id}>{user.nom} {user.prenom}  ({user.groupe.name})
                                        </Select.Option>)
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="N°Tel"
                            name="telephone"
                            rules={[
                                { required: true, message: 'Veuillez entrer le n°tel' },
                                {type:"string", message:"Entrer le numéro de téléphone!"},
                                {len:9,message:"Veillez saisir un numéro de téléphone valide !"}
                            ]}
                            hasFeedback>
                            <Input placeholder="entrer n°Tel"/>
                        </Form.Item>
                        <Form.Item
                            label="Adresse"
                            name="location"
                            hasFeedback>
                            <Input placeholder="Saisir votre adresse" />
                        </Form.Item>
                        <Form.Item
                            label="Description"
                            name="description"
                            hasFeedback>
                            <Input.TextArea row={4} placeholder="entrer la description"></Input.TextArea>
                        </Form.Item>
                        <Form.Item
                            label="Region"
                            name="region"
                            rules={
                                [
                                    { required: true, message: "Selectionner" },

                                ]}
                            hasFeedback>
                            <Select placeholder="Region">
                                <Select.Option key={"ADAMAOUA"} value={"ADAMAOUA"}>Adamaoua</Select.Option>
                                <Select.Option key={"CENTRE"} value={"CENTRE"}>Centre</Select.Option>
                                <Select.Option key={"EST"} value={"EST"}>Est</Select.Option>
                                <Select.Option key={"OUEST"} value={"OUEST"}>Ouest</Select.Option>
                                <Select.Option key={"NORD-OUEST"} value={"NORD-OUEST"}>Nord-Ouest</Select.Option>
                                <Select.Option key={"NORD"} value={"NORD"}>Nord</Select.Option>
                                <Select.Option key={"SUD"} value={"SUD"}>Sud</Select.Option>
                                <Select.Option key={"LITTORAL"} value={"LITTORAL"}>Littoral</Select.Option>
                                <Select.Option key={"EXTREME-NORD"} value={"EXTREME-NORD"}>Extrême-Nord</Select.Option>
                                <Select.Option key={"SUD-OUEST"} value={"SUD-OUEST"}>Sud-Ouest</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Commune"
                            name="nomCommune"
                            rules={[
                                { required: true, message: 'Veuillez entrer le nom de la commune' },
                                {type:"string", message:"Entrer le numéro de téléphone!"}
                            ]}
                            hasFeedback>
                            <Input placeholder="Entrer le nom de la commune"/>
                        </Form.Item>
                        <Form.Item
                            label="Nombre de Lits"
                            name="nombreLit"
                            rules={[
                                { required: true, message: 'Veuillez le nombre de lits' },
                                {pattern: /^[0-9]+$/, message: 'Veuillez le nombre de lits'}
                            ]}
                            hasFeedback>
                            <Input placeholder="Entrer Veuillez le nombre de lits"/>
                        </Form.Item>
                        <Form.Item
                            label="Nombre de Batiment"
                            name="nombreBat"
                            rules={[
                                { required: true, message: 'Veuillez entrer le nombre de batimant' },
                                {pattern: /^[0-9]+$/, message: 'Veuillez le nombre de batiments'}
                            ]}
                            hasFeedback>
                            <Input placeholder="Entrer Veuillez le nombre de batiments" />
                        </Form.Item>


                        <Form.Item
                            label="Categorie du Centre"
                            name="category"
                            rules={[
                                { required: true, message: 'Veuillez entrer la categorie' },
                                {pattern: /^[0-9]+$/, message: 'Veuillez entrer la categorie'}
                            ]}
                            hasFeedback>
                            <Input placeholder="Entrer la categorie"/>
                        </Form.Item>
                        <Form.Item
                            label="Superficie"
                            name="superficie"
                            rules={[
                                { required: true, message: 'Veuillez entrer la superficie' },
                                { pattern: /^[0-9]+$/, message: 'Veuillez entrer la superficie'}
                            ]}
                            hasFeedback>
                            <Input placeholder="Entrer la superficie"/>
                        </Form.Item>

                        <Form.Item
                            label="Morgue"
                            name="morgue"
                            rules={
                                [
                                    { required: true, message: "Selectionner" },

                                ]}
                            hasFeedback>
                            <Select placeholder="Le centre dispose t-il d'une morgue">
                                <Select.Option key={"oui"} value={true}>Oui</Select.Option>
                                <Select.Option key={"non"} value={false}>Non</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Fichier"
                            hasFeedback>
                            <input type="file" accept="image/png" onChange={getFiles}/>
                        </Form.Item>
                        <Form.Item>
                            <Space style={{float:'right'}}>
                                <Button type='primary' htmlType='submit' disabled={loading}>
                                    Submit
                                </Button>
                                <Button danger  disabled={loading}  onClick={() => setModalAddOpen(false)} >
                                    <i className="fa fa-times" aria-hidden="true"></i> cancel
                                </Button>

                            </Space>
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
        </>
    );
}