import {Button, Tooltip, message, Form, Spin, Input, Space, Modal, Tag, notification} from "antd";
import React, {useState} from "react";
import { useSelector } from "react-redux";
import axiosInstance from "../../configurations/axiosInstance/AxiosInstance";

export function OneSms({num,  message}) {
    const {accessToken} = useSelector(state => state.authUser);
    const [modalAddOpen, setModalAddOpen] = useState(false);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const openModal =()=>{
        form.resetFields();
        setModalAddOpen(true)
    }

    const onChange = (value)=>{
        setLoading(true);
        axiosInstance(accessToken).post(
            "api/message/sendsms",{
                phone:num,
                message:value.message
            }
        ).then((result)=> {
            notification.info({
                style: {
                    zIndex: 0
                },
                message: 'SMS',
                description:
                    'SMS encours d\'envois',

            })
            setLoading(false);
            setModalAddOpen(false);
        }).catch((result)=>{
            notification.error({
                style: {
                    zIndex: 0
                },
                message: 'SMS',
                description:
                    'Echec',

            })
            setLoading(false);
            setModalAddOpen(false);
        });
    }
    return (
        <>
            <Tooltip title={"Send SMS"}>
                <Button color='green' style={{float: "right", marginLeft: "5px"}}  onClick={openModal} >
                    {message}
                </Button>
            </Tooltip>
            <Modal
                title="Send Message"
                style={{
                    top: 20,
                }}
                width={1000}
                open={modalAddOpen}
                maskClosable={false}
                closable={false}
                footer={[]}
            >
                <Spin spinning={loading}>
                    <Form onFinish={onChange} form={form}>
                        <Form.Item
                            label="Message"
                            name="message"
                            rules={[
                                { required: true, message: 'Veuillez le contenu du message' },
                                { min: 4, message: 'Veuillez le contenu du message' },
                                { max: 255, message: 'Veuillez le contenu du message' },
                            ]}
                            hasFeedback>
                            <Input.TextArea row={4} size={255} placeholder="Saisir votre message" />
                        </Form.Item>
                        <Form.Item>
                            <Space style={{float:'right'}}>
                                <Button type='primary' htmlType='submit' disabled={loading}>
                                    Submit
                                </Button>
                                <Button danger  disabled={loading}  onClick={() => setModalAddOpen(false)} >
                                    cancel
                                </Button>

                            </Space>
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
        </>
    )
}